export default [
    "abo",
    "chink",
    "coon",
    "dago",
    "fag",
    "faggot",
    "gook",
    "jap",
    "kike",
    "mong",
    "nazi",
    "nigga",
    "nigger",
    "paki",
    "retard",
    "spastic",
    "spic",
    "trannie",
    "tranny",
    "wetback",
    "wog",
]
