export const GameTypes = ["Mafia", "Split Decision", "Resistance", "One Night", "Jotto"];
export const Lobbies = ["Main", "Sandbox", "Competitive", "Games"];

export const Alignments = {
    "Mafia": ["Village", "Mafia", "Monsters", "Independent"],
    "Split Decision": ["Blue", "Red", "Independent"],
    "Resistance": ["Resistance", "Spies"],
    "One Night": ["Village", "Werewolves", "Independent"],
    "Jotto": ["Jotter"],
};

export const GameStates = {
    "Mafia": ["Day", "Night"],
    "Split Decision": ["Initial Round", "Hostage Swap"],
    "Resistance": ["Team Selection", "Team Approval", "Mission"],
    "One Night": ["Day", "Night"],
    "Jotto": ["Choose Word", "Guess Word"],
};

export const RatingThresholds = {
    "reads": {
        "S": 0.95,
        "A": 0.9,
        "B": 0.8,
        "C": 0.7,
        "D": 0.6,
        "E": 0.5,
    },
    "survival": {
        "S": 0.95,
        "A": 0.9,
        "B": 0.8,
        "C": 0.7,
        "D": 0.6,
        "E": 0.5,
    },
    "wins": {}
};

export const RequiredTotalForStats = 1;

export const MaxGameMessageLength = 240;
export const MaxTextInputLength = 100;
export const MaxWillLength = 100;

export const MaxGroupNameLength = 20;
export const MaxCategoryNameLength = 20;
export const MaxBoardNameLength = 20;
export const MaxBoardDescLength = 60;
export const MaxThreadTitleLength = 50;
export const MaxThreadContentLength = 5000;
export const MaxReplyLength = 1000;

export const MaxChatMessageLength = 240;

export const AlertFadeTimeout = 3000;
export const AlertFadeDuration = 500;

export const JottoLegalWords = {
    "A": [
        "ABETS", "ABHOR", "ABIDE", "ABIES", "ABLED", "ABLER", "ABLES", "ABLET", "ABLOW", "ABMHO", "ABODE", "ABOHM", 
        "ABOIL", "ABORD", "ABORE", "ABORT", "ABOUT", "ABOVE", "ABRIM", "ABRIN", "ABRIS", "ABSEY", "ABSIT", "ABUNE", 
        "ABUSE", "ABUTS", "ABYES", "ABYSM", "ACERB", "ACERS", "ACHED", "ACHES", "ACIDS", "ACIDY", "ACING", "ACKER", 
        "ACMES", "ACNED", "ACNES", "ACOLD", "ACORN", "ACRED", "ACRES", "ACRID", "ACTED", "ACTIN", "ACTON", "ACTOR", 
        "ACUTE", "ACYLS", "ADEPT", "ADIEU", "ADIOS", "ADITS", "ADMEN", "ADMIN", "ADMIT", "ADMIX", "ADOBE", "ADOPT", 
        "ADORE", "ADORN", "ADOWN", "ADOZE", "ADSUM", "ADUKI", "ADULT", "ADUNC", "ADUST", "ADVEW", "ADZES", "AEGIS", 
        "AEONS", "AEROS", "AESIR", "AFIRE", "AFORE", "AFOUL", "AFRIT", "AFROS", "AFTER", "AGENT", "AGERS", "AGILE", 
        "AGIOS", "AGISM", "AGIST", "AGLET", "AGLEY", "AGLOW", "AGLUS", "AGONE", "AGONS", "AGONY", "AGRIN", "AGUED", 
        "AGUES", "AGUTI", "AHENT", "AHIND", "AHING", "AHINT", "AHOLD", "AIDER", "AIDES", "AIDOS", "AIERY", "AILED", 
        "AIMED", "AIMER", "AIRED", "AIRNS", "AIRTH", "AIRTS", "AISLE", "AITCH", "AITUS", "AIVER", "AIZLE", "AKING", 
        "ALBUM", "ALCID", "ALCOS", "ALDER", "ALECK", "ALECS", "ALEFS", "ALEFT", "ALEPH", "ALERT", "ALEWS", "ALGID", 
        "ALGIN", "ALGOR", "ALGUM", "ALIEN", "ALIFS", "ALIGN", "ALIKE", "ALINE", "ALIST", "ALIVE", "ALKIE", "ALKOS", 
        "ALKYD", "ALMEH", "ALMES", "ALMUD", "ALMUG", "ALODS", "ALOED", "ALOES", "ALOFT", "ALOIN", "ALONE", "ALONG", 
        "ALOUD", "ALOWE", "ALTER", "ALTHO", "ALTOS", "ALUMS", "ALURE", "AMBER", "AMBIT", "AMBLE", "AMBOS", "AMBRY", 
        "AMEND", "AMENS", "AMENT", "AMICE", "AMIDE", "AMIDO", "AMIDS", "AMIES", "AMIGO", "AMINE", "AMINO", "AMINS", 
        "AMIRS", "AMITY", "AMNIC", "AMNIO", "AMOKS", "AMOLE", "AMONG", "AMORT", "AMOUR", "AMOVE", "AMOWT", "AMPED", 
        "AMPLE", "AMPLY", "AMPUL", "AMRIT", "AMUCK", "AMUSE", "AMYLS", "ANCHO", "ANCLE", "ANDRO", "ANGEL", "ANGER", 
        "ANGLE", "ANGLO", "ANGRY", "ANGST", "ANIGH", "ANILE", "ANILS", "ANIME", "ANISE", "ANKER", "ANKHS", "ANKLE", 
        "ANKUS", "ANODE", "ANOLE", "ANOMY", "ANTED", "ANTES", "ANTIC", "ANTIS", "ANTRE", "ANTSY", "ANVIL", "APERS", 
        "APERT", "APERY", "APHID", "APHIS", "APING", "APIOL", "APISH", "APISM", "APODE", "APODS", "APORT", "APRES", 
        "APRON", "APTED", "APTER", "APTLY", "ARCED", "ARCUS", "ARDEB", "AREFY", "AREIC", "ARETS", "ARGIL", "ARGLE", 
        "ARGOL", "ARGON", "ARGOT", "ARGUE", "ARGUS", "ARIEL", "ARILS", "ARIOT", "ARISE", "ARISH", "ARKED", "ARLED", 
        "ARLES", "ARMED", "ARMET", "ARMIL", "ARNUT", "AROID", "AROSE", "ARPEN", "ARSED", "ARSEY", "ARSON", "ARTEL", 
        "ARTIC", "ARTIS", "ARTSY", "ARUHE", "ARUMS", "ARVOS", "ARYLS", "ASCOT", "ASDIC", "ASHED", "ASHEN", "ASHET", 
        "ASIDE", "ASKED", "ASKER", "ASKEW", "ASKOI", "ASPEN", "ASPER", "ASPIC", "ASPRO", "ASTER", "ASTIR", "ASTUN", 
        "ASWIM", "ATIMY", "ATOCS", "ATOKE", "ATOKS", "ATOMS", "ATOMY", "ATONE", "ATONY", "ATOPY", "ATRIP", "AUDIO", 
        "AUDIT", "AUGER", "AUGHT", "AULIC", "AULOI", "AULOS", "AUMIL", "AUNES", "AUNTS", "AUNTY", "AUREI", "AURES", 
        "AURIC", "AURIS", "AUTOS", "AUXIN", "AVELS", "AVENS", "AVERS", "AVERT", "AVINE", "AVION", "AVISE", "AVISO", 
        "AVIZE", "AVOID", "AVOWS", "AVYZE", "AWDLS", "AWETO", "AWFUL", "AWING", "AWMRY", "AWNED", "AWNER", "AWOKE", 
        "AWOLS", "AWORK", "AXELS", "AXILE", "AXILS", "AXING", "AXIOM", "AXION", "AXITE", "AXLED", "AXLES", "AXMEN", 
        "AXOID", "AXONE", "AXONS", "AYELP", "AYGRE", "AYINS", "AYONT", "AYRES", "AYRIE", "AZIDE", "AZIDO", "AZINE", 
        "AZLON", "AZOIC", "AZOLE", "AZONS", "AZOTE", "AZOTH", "AZUKI", "AZURE", "AZURN", "AZURY", "AZYME", "AZYMS"
    ],
    "B": [
        "BACHS", "BACKS", "BACON", "BADGE", "BADLY", "BAELS", "BAFTS", "BAGEL", "BAGHS", "BAGIE", "BAHTS", "BAHUT", 
        "BAILS", "BAIRN", "BAITH", "BAITS", "BAIZE", "BAJRI", "BAJUS", "BAKED", "BAKEN", "BAKER", "BAKES", "BALDS", 
        "BALDY", "BALED", "BALER", "BALES", "BALKS", "BALKY", "BALMS", "BALMY", "BALTI", "BALUN", "BALUS", "BANCO", 
        "BANCS", "BANDH", "BANDS", "BANDY", "BANED", "BANES", "BANGS", "BANJO", "BANKS", "BANTS", "BANTU", "BANTY", 
        "BAPUS", "BARDE", "BARDO", "BARDS", "BARDY", "BARED", "BARES", "BARFS", "BARGE", "BARIC", "BARKS", "BARKY", 
        "BARMS", "BARMY", "BARNS", "BARNY", "BARON", "BARPS", "BARYE", "BASED", "BASER", "BASHO", "BASIC", "BASIL", 
        "BASIN", "BASON", "BASTE", "BASTI", "BASTO", "BATCH", "BATED", "BATES", "BATHE", "BATHS", "BATIK", "BATON", 
        "BAUDS", "BAUKS", "BAULK", "BAURS", "BAVIN", "BAWDS", "BAWDY", "BAWLS", "BAWNS", "BAWRS", "BAWTY", "BAYED", 
        "BAYES", "BAYLE", "BAYOU", "BAYTS", "BEACH", "BEADS", "BEADY", "BEAKS", "BEAKY", "BEAMS", "BEAMY", "BEANO", 
        "BEANS", "BEANY", "BEARD", "BEARS", "BEAST", "BEATH", "BEATS", "BEATY", "BEAUS", "BEAUT", "BEAUX", "BECAP", 
        "BECKS", "BEDIM", "BEFIT", "BEFOG", "BEGAD", "BEGAN", "BEGAR", "BEGAT", "BEGIN", "BEGOT", "BEGUM", "BEGUN", 
        "BEIGY", "BEING", "BEKAH", "BELAH", "BELAR", "BELAY", "BELCH", "BELGA", "BELON", "BELOW", "BELTS", "BEMAD", 
        "BEMAS", "BEMIX", "BEMUD", "BENCH", "BENDS", "BENDY", "BENIS", "BENTO", "BENTS", "BENTY", "BEPAT", "BERAY", 
        "BERGS", "BERKO", "BERKS", "BERMS", "BERTH", "BERYL", "BESAT", "BESAW", "BESIT", "BESOM", "BESOT", "BESTI", 
        "BETAS", "BETHS", "BETID", "BETON", "BEVOR", "BEWIG", "BEZIL", "BHAJI", "BHANG", "BHELS", "BHUNA", "BHUTS", 
        "BIALY", "BICEP", "BICES", "BIDER", "BIDES", "BIDET", "BIDON", "BIELD", "BIERS", "BIGAE", "BIGHA", "BIGHT", 
        "BIGLY", "BIGOS", "BIGOT", "BIJOU", "BIKED", "BIKER", "BIKES", "BILED", "BILES", "BILGE", "BILGY", "BILKS", 
        "BIMAH", "BIMAS", "BINAL", "BINDS", "BINER", "BINES", "BINGE", "BINGO", "BINGS", "BINGY", "BINKS", "BINTS", 
        "BIOGS", "BIOME", "BIONT", "BIOTA", "BIPED", "BIPOD", "BIRCH", "BIRDS", "BIRKS", "BIRLE", "BIRLS", "BIROS", 
        "BIRSE", "BIRSY", "BIRTH", "BISON", "BITCH", "BITER", "BITES", "BITOS", "BITOU", "BITSY", "BIZES", "BLACK", 
        "BLADE", "BLADS", "BLADY", "BLAER", "BLAES", "BLAGS", "BLAHS", "BLAIN", "BLAME", "BLAMS", "BLAND", "BLANK", 
        "BLARE", "BLART", "BLASE", "BLASH", "BLAST", "BLATE", "BLATS", "BLAUD", "BLAWN", "BLAWS", "BLAYS", "BLAZE", 
        "BLEAK", "BLEAR", "BLEAT", "BLEND", "BLENT", "BLERT", "BLEST", "BLETS", "BLEYS", "BLIMP", "BLIMY", "BLIND", 
        "BLING", "BLINK", "BLINS", "BLINY", "BLIPS", "BLIST", "BLITE", "BLITZ", "BLIVE", "BLOAT", "BLOCK", "BLOCS", 
        "BLOGS", "BLOKE", "BLOND", "BLORE", "BLOTS", "BLOWN", "BLOWS", "BLOWY", "BLUDE", "BLUDY", "BLUED", "BLUER", 
        "BLUES", "BLUET", "BLUEY", "BLUID", "BLUME", "BLUNK", "BLUNT", "BLURS", "BLURT", "BLUSH", "BLYPE", "BOAKS", 
        "BOARD", "BOARS", "BOART", "BOAST", "BOATS", "BOCHE", "BOCKS", "BODES", "BODGE", "BODLE", "BOEPS", "BOETS", 
        "BOEUF", "BOGAN", "BOGEY", "BOGIE", "BOGLE", "BOGUS", "BOHEA", "BOILS", "BOING", "BOINK", "BOITE", "BOKED", 
        "BOKES", "BOLAR", "BOLAS", "BOLDS", "BOLES", "BOLIX", "BOLTS", "BOLUS", "BOMAS", "BONCE", "BONDS", "BONED", 
        "BONER", "BONES", "BONEY", "BONGS", "BONIE", "BONKS", "BONUS", "BONZA", "BONZE", "BORAK", "BORAL", "BORAS", 
        "BORAX", "BORDE", "BORDS", "BORED", "BOREL", "BORES", "BORIC", "BORKS", "BORMS", "BORNA", "BORNE", "BORTS", 
        "BORTY", "BORTZ", "BOSKY", "BOSUN", "BOTAS", "BOTCH", "BOTEL", "BOTHY", "BOUGE", "BOUGH", "BOUKS", "BOULE", 
        "BOULT", "BOUND", "BOUNS", "BOURD", "BOURG", "BOURN", "BOUSE", "BOUSY", "BOUTS", "BOVID", "BOWAT", "BOWED", 
        "BOWEL", "BOWER", "BOWES", "BOWET", "BOWIE", "BOWLS", "BOWNE", "BOWRS", "BOWSE", "BOXED", "BOXEN", "BOXER", 
        "BOXES", "BOYAR", "BOYAU", "BOYED", "BOYFS", "BOYGS", "BOYLA", "BRACE", "BRACH", "BRACK", "BRACT", "BRADS", 
        "BRAES", "BRAGS", "BRAID", "BRAIL", "BRAIN", "BRAKE", "BRAKS", "BRAKY", "BRAME", "BRAND", "BRANK", "BRANS", 
        "BRANT", "BRASH", "BRAST", "BRATS", "BRAVE", "BRAVI", "BRAVO", "BRAWL", "BRAWN", "BRAWS", "BRAXY", "BRAYS", 
        "BRAZE", "BREAD", "BREAK", "BREAM", "BREDS", "BREID", "BREIS", "BRENS", "BRENT", "BREWS", "BREYS", "BRICK", 
        "BRIDE", "BRIEF", "BRIES", "BRIGS", "BRIKS", "BRIMS", "BRINE", "BRING", "BRINK", "BRINS", "BRINY", "BRIOS", 
        "BRISE", "BRISK", "BRITH", "BRITS", "BRIZE", "BROAD", "BROCH", "BROCK", "BRODS", "BROGH", "BROGS", "BROIL", 
        "BROKE", "BROME", "BRONC", "BROND", "BROSE", "BROSY", "BROTH", "BROWN", "BROWS", "BRUGH", "BRUIN", "BRUIT", 
        "BRULE", "BRUME", "BRUNG", "BRUNT", "BRUSH", "BRUSK", "BRUST", "BRUTE", "BRUTS", "BUATS", "BUAZE", "BUCKO", 
        "BUCKS", "BUDAS", "BUDGE", "BUDIS", "BUDOS", "BUFOS", "BUGLE", "BUHLS", "BUHRS", "BUIKS", "BUILD", "BUILT", 
        "BUIST", "BUKES", "BULGE", "BULGY", "BULKS", "BULKY", "BULSE", "BUMFS", "BUMPH", "BUMPS", "BUMPY", "BUNAS", 
        "BUNCE", "BUNCH", "BUNCO", "BUNDE", "BUNDH", "BUNDS", "BUNDT", "BUNDY", "BUNGS", "BUNGY", "BUNIA", "BUNJE", 
        "BUNJY", "BUNKO", "BUNKS", "BUNTS", "BUNTY", "BUNYA", "BUOYS", "BURAN", "BURAS", "BURDS", "BURET", "BURGH", 
        "BURGS", "BURIN", "BURKA", "BURKE", "BURKS", "BURLS", "BURLY", "BURNS", "BURNT", "BURPS", "BURQA", "BURSA", 
        "BURSE", "BURST", "BUSED", "BUSHY", "BUSKY", "BUSTI", "BUSTY", "BUTCH", "BUTEO", "BUTES", "BUTLE", "BUTYL", 
        "BUXOM", "BUYER", "BWAZI", "BYDES", "BYKED", "BYKES", "BYLAW", "BYRES", "BYRLS", "BYTES"
    ],
    "C": [
        "CABER", "CABIN", "CABLE", "CABRE", "CADES", "CADET", "CADGE", "CADGY", "CADIE", "CADIS", "CADRE", "CAFES", 
        "CAGED", "CAGER", "CAGES", "CAGEY", "CAGOT", "CAHOW", "CAIDS", "CAINS", "CAIRD", "CAIRN", "CAJON", "CAJUN", 
        "CAKED", "CAKES", "CAKEY", "CALFS", "CALID", "CALIF", "CALIX", "CALKS", "CALMS", "CALMY", "CALOS", "CALPS", 
        "CALVE", "CALYX", "CAMEL", "CAMEO", "CAMES", "CAMIS", "CAMOS", "CAMPI", "CAMPO", "CAMPS", "CAMPY", "CAMUS", 
        "CANDY", "CANED", "CANEH", "CANER", "CANES", "CANGS", "CANID", "CANOE", "CANSO", "CANST", "CANTO", "CANTS", 
        "CANTY", "CAPED", "CAPER", "CAPES", "CAPHS", "CAPIZ", "CAPLE", "CAPON", "CAPOS", "CAPOT", "CAPUL", "CAPUT", 
        "CARBO", "CARBS", "CARBY", "CARDI", "CARDS", "CARDY", "CARED", "CARES", "CARET", "CAREX", "CARGO", "CARKS", 
        "CARLE", "CARLS", "CARNS", "CARNY", "CAROB", "CAROL", "CAROM", "CARPI", "CARPS", "CARSE", "CARTE", "CARTS", 
        "CARVE", "CARVY", "CASED", "CASKY", "CASTE", "CATER", "CATES", "CAUKS", "CAULD", "CAULK", "CAULS", "CAUMS", 
        "CAUPS", "CAUSE", "CAVED", "CAVEL", "CAVER", "CAVES", "CAVIE", "CAVIL", "CAWED", "CAWKS", "CAXON", "CEBID", 
        "CEDAR", "CEDIS", "CEIBA", "CEILS", "CELOM", "CELTS", "CENTO", "CENTS", "CENTU", "CEORL", "CERIA", "CEROS", 
        "CERTS", "CESTA", "CESTI", "CETYL", "CHADO", "CHADS", "CHAFE", "CHAFT", "CHAIN", "CHAIR", "CHAIS", "CHALK", 
        "CHALS", "CHAMP", "CHAMS", "CHANG", "CHANK", "CHANT", "CHAOS", "CHAPE", "CHAPS", "CHAPT", "CHARD", "CHARE", 
        "CHARK", "CHARM", "CHARS", "CHART", "CHARY", "CHASE", "CHASM", "CHATS", "CHAVE", "CHAVS", "CHAWK", "CHAWS", 
        "CHAYS", "CHEAP", "CHEAT", "CHEFS", "CHEKA", "CHELA", "CHELP", "CHEMO", "CHERT", "CHEST", "CHEVY", "CHEWS", 
        "CHEWY", "CHIAO", "CHIAS", "CHIBS", "CHIDE", "CHIEF", "CHIEL", "CHIKS", "CHILD", "CHILE", "CHIMB", "CHIME", 
        "CHIMO", "CHIMP", "CHINA", "CHINE", "CHINK", "CHINO", "CHINS", "CHIPS", "CHIRK", "CHIRL", "CHIRM", "CHIRO", 
        "CHIRP", "CHIRT", "CHIRU", "CHITS", "CHIVE", "CHIVS", "CHIVY", "CHODE", "CHOGS", "CHOIR", "CHOKE", "CHOKY", 
        "CHOLA", "CHOLI", "CHOMP", "CHONS", "CHOPS", "CHORD", "CHORE", "CHOSE", "CHOTA", "CHOUT", "CHOUX", "CHOWK", 
        "CHOWS", "CHUBS", "CHUFA", "CHUGS", "CHUMP", "CHUMS", "CHUNK", "CHURL", "CHURN", "CHUSE", "CHUTE", "CHYLE", 
        "CHYME", "CHYND", "CIAOS", "CIBOL", "CIDER", "CIDES", "CIELS", "CIGAR", "CIMAR", "CIMEX", "CINES", "CIONS", 
        "CIRES", "CIRLS", "CITAL", "CITED", "CITER", "CITES", "CIVES", "CIVET", "CLADE", "CLADS", "CLAES", "CLAGS", 
        "CLAIM", "CLAME", "CLAMP", "CLAMS", "CLANG", "CLANK", "CLANS", "CLAPS", "CLAPT", "CLARO", "CLART", "CLARY", 
        "CLASH", "CLASP", "CLAST", "CLATS", "CLAUT", "CLAVE", "CLAVI", "CLAWS", "CLAYS", "CLEAN", "CLEAR", "CLEAT", 
        "CLEFS", "CLEFT", "CLEGS", "CLEIK", "CLEMS", "CLEPT", "CLERK", "CLEWS", "CLIED", "CLIES", "CLIFT", "CLIMB", 
        "CLIME", "CLINE", "CLING", "CLINK", "CLINT", "CLIPE", "CLIPS", "CLIPT", "CLOAK", "CLOAM", "CLODS", "CLOGS", 
        "CLOKE", "CLOMB", "CLOMP", "CLONE", "CLONK", "CLONS", "CLOPS", "CLOSE", "CLOTE", "CLOTH", "CLOTS", "CLOUD", 
        "CLOUR", "CLOUS", "CLOUT", "CLOVE", "CLOWN", "CLOWS", "CLOYE", "CLOYS", "CLOZE", "CLUBS", "CLUED", "CLUES", 
        "CLUMP", "CLUNG", "CLUNK", "CLYPE", "CNIDA", "COALS", "COALY", "COAPT", "COARB", "COAST", "COATE", "COATI", 
        "COATS", "COBIA", "COBLE", "COBRA", "COBZA", "CODAS", "CODEN", "CODER", "CODES", "CODEX", "COEDS", "COGIE", 
        "COGUE", "COHAB", "COIFS", "COIGN", "COILS", "COINS", "COIRS", "COITS", "COKED", "COKES", "COLAS", "COLBY", 
        "COLDS", "COLED", "COLES", "COLEY", "COLIN", "COLTS", "COLZA", "COMAE", "COMAL", "COMAS", "COMBE", "COMBI", 
        "COMBS", "COMBY", "COMER", "COMES", "COMET", "COMFY", "COMIX", "COMPS", "COMPT", "COMTE", "COMUS", "CONED", 
        "CONES", "CONEY", "CONFS", "CONGA", "CONGE", "CONIA", "CONKS", "CONKY", "CONTE", "CONUS", "COPAL", "COPAY", 
        "COPED", "COPEN", "COPER", "COPES", "COPRA", "COPSE", "COPSY", "CORAL", "CORAM", "CORBE", "CORBY", "CORDS", 
        "CORED", "CORES", "COREY", "CORGI", "CORIA", "CORKS", "CORKY", "CORMS", "CORNI", "CORNS", "CORNU", "CORNY", 
        "CORPS", "CORSE", "COSED", "COSET", "COSEY", "COSIE", "COSTA", "COSTE", "COTAN", "COTED", "COTES", "COTHS", 
        "COUDE", "COUGH", "COULD", "COUNT", "COUPE", "COUPS", "COURB", "COURD", "COURE", "COURS", "COURT", "COUTH", 
        "COVED", "COVEN", "COVER", "COVES", "COVET", "COVEY", "COVIN", "COWAL", "COWAN", "COWED", "COWER", "COWKS", 
        "COWLS", "COWPS", "COWRY", "COXAE", "COXAL", "COXED", "COXES", "COYED", "COYER", "COYPU", "COZED", "COZEN", 
        "COZES", "COZEY", "COZIE", "CRABS", "CRAFT", "CRAGS", "CRAIG", "CRAKE", "CRAME", "CRAMP", "CRAMS", "CRANE", 
        "CRANK", "CRANS", "CRAPE", "CRAPS", "CRAPY", "CRASH", "CRATE", "CRAVE", "CRAWL", "CRAWS", "CRAYS", "CRAZE", 
        "CRAZY", "CREAK", "CREAM", "CREDO", "CREDS", "CREMS", "CRENA", "CREPT", "CREPY", "CREST", "CREWS", "CRIBS", 
        "CRIED", "CRIES", "CRIME", "CRIMP", "CRIMS", "CRINE", "CRIOS", "CRIPE", "CRISE", "CRISP", "CRITH", "CRITS", 
        "CROAK", "CROFT", "CROGS", "CROMB", "CROME", "CRONE", "CRONK", "CRONY", "CROPS", "CROST", "CROUP", "CROUT", 
        "CROWD", "CROWN", "CROWS", "CROZE", "CRUDE", "CRUDS", "CRUDY", "CRUEL", "CRUES", "CRUET", "CRUMB", "CRUMP", 
        "CRUSE", "CRUSH", "CRUST", "CRUSY", "CRUVE", "CRWTH", "CRYPT", "CUBED", "CUBER", "CUBES", "CUBIT", "CUIFS", 
        "CUING", "CUISH", "CUITS", "CUKES", "CULET", "CULEX", "CULMS", "CULPA", "CULTI", "CULTS", "CULTY", "CUMIN", 
        "CUNDY", "CUNEI", "CUNTS", "CUPEL", "CUPID", "CURAT", "CURBS", "CURDS", "CURDY", "CURED", "CURES", "CURET", 
        "CURFS", "CURIA", "CURIE", "CURIO", "CURLI", "CURLS", "CURLY", "CURNS", "CURNY", "CURSE", "CURSI", "CURST", 
        "CURVE", "CURVY", "CUSHY", "CUTER", "CUTES", "CUTEY", "CUTIE", "CUTIN", "CUTIS", "CYANO", "CYANS", "CYBER", 
        "CYDER", "CYLIX", "CYMAE", "CYMAR", "CYMAS", "CYMES", "CYMOL", "CYTES", "CYTON", "CZARS"
    ],
    "D": [
        "DACES", "DACKS", "DAGOS", "DAHLS", "DAILY", "DAINE", "DAINT", "DAIRY", "DAISY", "DAKER", "DALES", "DALIS", 
        "DALTS", "DAMES", "DAMNS", "DAMPS", "DAMPY", "DANCE", "DANGS", "DANIO", "DANKS", "DANTS", "DARBS", "DARCY", 
        "DARES", "DARGS", "DARIC", "DARIS", "DARKS", "DARKY", "DARNS", "DARTS", "DARZI", "DASHI", "DASHY", "DATER", 
        "DATES", "DATOS", "DATUM", "DAUBE", "DAUBS", "DAUBY", "DAULT", "DAUNT", "DAURS", "DAUTS", "DAVEN", "DAVIT", 
        "DAWEN", "DAWKS", "DAWNS", "DAWTS", "DAYCH", "DAYNT", "DAZER", "DAZES", "DEAIR", "DEALS", "DEALT", "DEANS", 
        "DEARN", "DEARS", "DEARY", "DEASH", "DEATH", "DEAWS", "DEAWY", "DEBAG", "DEBAR", "DEBIT", "DEBTS", "DEBUG", 
        "DEBUS", "DEBUT", "DECAF", "DECAL", "DECAY", "DECKO", "DECKS", "DECOR", "DECOS", "DECOY", "DECRY", "DEFAT", 
        "DEFIS", "DEFOG", "DEGAS", "DEGUM", "DEIFY", "DEIGN", "DEILS", "DEISM", "DEIST", "DEITY", "DELAY", "DELFS", 
        "DELFT", "DELIS", "DELOS", "DELPH", "DELTA", "DELTS", "DEMAN", "DEMIC", "DEMIT", "DEMOB", "DEMON", "DEMOS", 
        "DEMPT", "DEMUR", "DENAR", "DENAY", "DENIM", "DENIS", "DENTS", "DEOXY", "DEPOT", "DEPTH", "DERAT", "DERAY", 
        "DERBY", "DERIG", "DERMA", "DERMS", "DERNS", "DEROS", "DERTH", "DERVS", "DESHI", "DETOX", "DEVAS", "DEVIL", 
        "DEVON", "DEVOT", "DEWAN", "DEWAR", "DEWAX", "DHAKS", "DHALS", "DHOBI", "DHOLE", "DHOLS", "DHOTI", "DHOWS", 
        "DHUTI", "DIACT", "DIALS", "DIARY", "DIAZO", "DICER", "DICES", "DICEY", "DICHT", "DICKS", "DICKY", "DICOT", 
        "DICTA", "DICTS", "DICTY", "DIEBS", "DIETS", "DIGHT", "DIKAS", "DIKER", "DIKES", "DIKEY", "DIMER", "DIMES", 
        "DIMLY", "DIMPS", "DINAR", "DINER", "DINES", "DINGE", "DINGO", "DINGS", "DINGY", "DINKS", "DINKY", "DINOS", 
        "DINTS", "DIOLS", "DIOTA", "DIPSO", "DIRAM", "DIRGE", "DIRKE", "DIRKS", "DIRLS", "DIRTS", "DIRTY", "DISCO", 
        "DISHY", "DISME", "DITAL", "DITAS", "DITCH", "DITES", "DITSY", "DITZY", "DIVAN", "DIVAS", "DIVER", "DIVES", 
        "DIVOT", "DIWAN", "DIZEN", "DJINS", "DOABS", "DOATS", "DOBIE", "DOBLA", "DOBRA", "DOCHT", "DOCKS", "DOEKS", 
        "DOERS", "DOEST", "DOETH", "DOGES", "DOGEY", "DOGIE", "DOGMA", "DOILT", "DOILY", "DOING", "DOITS", "DOLCE", 
        "DOLCI", "DOLES", "DOLIA", "DOLMA", "DOLTS", "DOMAL", "DOMES", "DOMIC", "DONAH", "DONAS", "DONER", "DONGA", 
        "DONGS", "DONSY", "DONUT", "DOPAS", "DOPER", "DOPES", "DOPEY", "DORBA", "DORBS", "DORIC", "DORIS", "DORKS", 
        "DORKY", "DORMS", "DORMY", "DORPS", "DORSA", "DORSE", "DORTS", "DORTY", "DOSEH", "DOSER", "DOTAL", "DOTER", 
        "DOTES", "DOUAR", "DOUBT", "DOUCE", "DOUCS", "DOUGH", "DOUKS", "DOULA", "DOUMA", "DOUMS", "DOUPS", "DOURA", 
        "DOUSE", "DOUTS", "DOVEN", "DOVER", "DOVES", "DOVIE", "DOWAR", "DOWEL", "DOWER", "DOWIE", "DOWLE", "DOWLS", 
        "DOWLY", "DOWNA", "DOWNS", "DOWNY", "DOWPS", "DOWRY", "DOWSE", "DOWTS", "DOXIE", "DOYEN", "DOZEN", "DOZER", 
        "DOZES", "DRABS", "DRACK", "DRACO", "DRAFT", "DRAGS", "DRAIL", "DRAIN", "DRAKE", "DRAMS", "DRANK", "DRANT", 
        "DRAPE", "DRAPS", "DRATS", "DRAVE", "DRAWL", "DRAWN", "DRAWS", "DRAYS", "DREAM", "DRECK", "DREGS", "DREKS", 
        "DRENT", "DREST", "DREYS", "DRIBS", "DRICE", "DRIES", "DRIFT", "DRILY", "DRINK", "DRIPS", "DRIPT", "DRIVE", 
        "DROIL", "DROIT", "DROLE", "DROME", "DRONE", "DRONY", "DROPS", "DROPT", "DROUK", "DROVE", "DROWN", "DROWS", 
        "DRUBS", "DRUGS", "DRUMS", "DRUNK", "DRUPE", "DRUSE", "DRUSY", "DRUXY", "DUALS", "DUANS", "DUARS", "DUCAL", 
        "DUCAT", "DUCES", "DUCHY", "DUCKS", "DUCKY", "DUCTS", "DUELS", "DUETS", "DUING", "DUITS", "DUKAS", "DUKES", 
        "DULES", "DULIA", "DULSE", "DUMAS", "DUMBO", "DUMBS", "DUMKA", "DUMKY", "DUMPS", "DUMPY", "DUNAM", "DUNCE", 
        "DUNCH", "DUNES", "DUNGS", "DUNGY", "DUNKS", "DUNSH", "DUNTS", "DUOMI", "DUPER", "DUPES", "DUPLE", "DUPLY", 
        "DURAL", "DURAS", "DURES", "DURGY", "DURNS", "DUROC", "DUROS", "DUROY", "DURST", "DURZI", "DUSKY", "DUSTY", 
        "DUTCH", "DUVET", "DUXES", "DWALE", "DWALM", "DWAMS", "DWANG", "DWARF", "DWAUM", "DWELT", "DWILE", "DWINE", 
        "DYERS", "DYING", "DYKES", "DYNEL", "DYNES", "DZHOS"
    ],
    "E": [
        "EARDS", "EARLS", "EARLY", "EARNS", "EARST", "EARTH", "EBONS", "EBONY", "ECADS", "ECHOS", "ECLAT", "ECRUS", 
        "EDICT", "EDIFY", "EDITS", "EDUCT", "EGADS", "EGMAS", "EHING", "EIDOS", "EIGHT", "EIKON", "EILDS", "EKING", 
        "ELAIN", "ELAND", "ELANS", "ELBOW", "ELCHI", "ELDIN", "ELFIN", "ELIAD", "ELINT", "ELOGY", "ELOIN", "ELOPS", 
        "ELSIN", "ELVAN", "EMACS", "EMAIL", "EMBAR", "EMBAY", "EMBOG", "EMBOW", "EMBOX", "EMBUS", "EMIRS", "EMITS", 
        "EMONG", "EMPTS", "EMPTY", "EMYDS", "ENACT", "ENARM", "ENDOW", "ENFIX", "ENIAC", "ENJOY", "ENLIT", "ENOKI", 
        "ENOLS", "ENORM", "ENOWS", "ENROL", "ENSKY", "ENTIA", "ENTRY", "ENVOI", "ENVOY", "ENZYM", "EORLS", "EOSIN", 
        "EPACT", "EPHAS", "EPHOD", "EPHOR", "EPICS", "EPOCH", "EPOXY", "EPRIS", "EPROM", "EQUAL", "EQUID", "EQUIP", 
        "ERBIA", "ERGON", "ERGOS", "ERGOT", "ERICA", "ERICK", "ERICS", "ERING", "ERUCT", "ERUGO", "ERUPT", "ERUVS", 
        "ERVIL", "ESCAR", "ESCOT", "ESKAR", "ESTOC", "ESTOP", "ESTRO", "ETHAL", "ETHIC", "ETHOS", "ETHYL", "ETNAS", 
        "ETUIS", "ETYMA", "EUGHS", "EUPAD", "EUROS", "EUSOL", "EVICT", "EVILS", "EXACT", "EXALT", "EXAMS", "EXING", 
        "EXIST", "EXITS", "EXONS", "EXPAT", "EXPOS", "EXTOL", "EXTRA", "EXULS", "EXULT", "EXURB", "EYING", "EYOTS", 
        "EYRAS"
    ],
    "F": [
        "FABLE", "FACED", "FACER", "FACES", "FACET", "FACTS", "FADER", "FADES", "FADGE", "FADOS", "FAERY", "FAGIN", 
        "FAGOT", "FAIKS", "FAILS", "FAINE", "FAINS", "FAINT", "FAIRS", "FAIRY", "FAITH", "FAKED", "FAKER", "FAKES", 
        "FAKEY", "FAKIR", "FALSE", "FAMED", "FAMES", "FANCY", "FANDS", "FANES", "FANGO", "FANGS", "FANKS", "FANOS", 
        "FANUM", "FAQIR", "FARCE", "FARCI", "FARCY", "FARDS", "FARED", "FARES", "FARLE", "FARLS", "FARMS", "FAROS", 
        "FARSE", "FARTS", "FASCI", "FASTI", "FATED", "FATES", "FATLY", "FATSO", "FAUGH", "FAULD", "FAULT", "FAUNS", 
        "FAURD", "FAUTS", "FAUVE", "FAVEL", "FAVER", "FAVES", "FAVOR", "FAVUS", "FAWNS", "FAWNY", "FAXED", "FAXES", 
        "FAYED", "FAYER", "FAYNE", "FAYRE", "FAZED", "FAZES", "FEALS", "FEARS", "FEAST", "FEATS", "FECAL", "FECHT", 
        "FECIT", "FECKS", "FEIGN", "FEINT", "FEIST", "FELID", "FELON", "FELTS", "FELTY", "FEMAL", "FEMUR", "FENDS", 
        "FENDY", "FENIS", "FENKS", "FENTS", "FEODS", "FERAL", "FERIA", "FERLY", "FERMI", "FERMS", "FERNS", "FERNY", 
        "FESTA", "FESTY", "FETAL", "FETAS", "FETCH", "FETID", "FETOR", "FETUS", "FETWA", "FEUAR", "FEUDS", "FIARS", 
        "FIATS", "FIBER", "FIBRE", "FIBRO", "FICES", "FICHE", "FICHU", "FICOS", "FICUS", "FIDGE", "FIDOS", "FIELD", 
        "FIEND", "FIENT", "FIERS", "FIERY", "FIEST", "FIGHT", "FIGOS", "FIKED", "FIKES", "FILAR", "FILCH", "FILED", 
        "FILER", "FILES", "FILET", "FILMS", "FILMY", "FILOS", "FILTH", "FILUM", "FINAL", "FINCA", "FINCH", "FINDS", 
        "FINED", "FINER", "FINES", "FINKS", "FINOS", "FIORD", "FIQUE", "FIRED", "FIRES", "FIRKS", "FIRMS", "FIRNS", 
        "FIRST", "FIRTH", "FISHY", "FISTY", "FITCH", "FITLY", "FITNA", "FIVER", "FIVES", "FIXED", "FIXER", "FIXES", 
        "FJELD", "FJORD", "FLABS", "FLACK", "FLAGS", "FLAIR", "FLAKE", "FLAKS", "FLAKY", "FLAME", "FLAMS", "FLAMY", 
        "FLANK", "FLANS", "FLAPS", "FLARE", "FLARY", "FLASH", "FLASK", "FLATS", "FLAWN", "FLAWS", "FLAWY", "FLAXY", 
        "FLAYS", "FLEAM", "FLEAS", "FLECK", "FLEGS", "FLESH", "FLEWS", "FLEXO", "FLEYS", "FLICK", "FLICS", "FLIED", 
        "FLIER", "FLIES", "FLIMP", "FLIMS", "FLING", "FLINT", "FLIPS", "FLIRS", "FLIRT", "FLISK", "FLITE", "FLITS", 
        "FLOAT", "FLOCK", "FLOCS", "FLOES", "FLOGS", "FLONG", "FLOPS", "FLORA", "FLORS", "FLORY", "FLOSH", "FLOTA", 
        "FLOTE", "FLOUR", "FLOUT", "FLOWN", "FLOWS", "FLUBS", "FLUED", "FLUES", "FLUEY", "FLUID", "FLUKE", "FLUKY", 
        "FLUME", "FLUMP", "FLUNG", "FLUNK", "FLUOR", "FLUSH", "FLUTE", "FLUTY", "FLUYT", "FLYER", "FLYPE", "FLYTE", 
        "FOALS", "FOAMS", "FOAMY", "FOCAL", "FOCUS", "FOEHN", "FOGEY", "FOGIE", "FOGLE", "FOHNS", "FOIDS", "FOILS", 
        "FOINS", "FOIST", "FOLDS", "FOLEY", "FOLIA", "FOLIC", "FOLIE", "FOLKS", "FOLKY", "FOMES", "FONDA", "FONDS", 
        "FONDU", "FONLY", "FONTS", "FORAM", "FORAY", "FORBS", "FORBY", "FORCE", "FORDS", "FOREL", "FORES", "FOREX", 
        "FORGE", "FORKS", "FORKY", "FORME", "FORMS", "FORTE", "FORTH", "FORTS", "FORTY", "FORUM", "FORZA", "FORZE", 
        "FOUAT", "FOUDS", "FOUER", "FOUET", "FOULE", "FOULS", "FOUND", "FOUNT", "FOURS", "FOUTH", "FOVEA", "FOWLS", 
        "FOWTH", "FOXED", "FOXES", "FOXIE", "FOYER", "FOYLE", "FOYNE", "FRABS", "FRACK", "FRACT", "FRAGS", "FRAIL", 
        "FRAIM", "FRAME", "FRANC", "FRANK", "FRAPE", "FRAPS", "FRATE", "FRATI", "FRATS", "FRAUD", "FRAUS", "FRAYS", 
        "FREAK", "FREIT", "FREMD", "FRENA", "FREON", "FRESH", "FRETS", "FRIBS", "FRIED", "FRIES", "FRIGS", "FRISE", 
        "FRISK", "FRIST", "FRITH", "FRITS", "FRITZ", "FRIZE", "FROCK", "FROES", "FROGS", "FROND", "FRONS", "FRONT", 
        "FROSH", "FROST", "FROTH", "FROWN", "FROWS", "FROWY", "FROZE", "FRUGS", "FRUIT", "FRUMP", "FRUSH", "FRUST", 
        "FUBAR", "FUBSY", "FUCKS", "FUDGE", "FUELS", "FUERO", "FUGAL", "FUGIE", "FUGIO", "FUGLE", "FUGLY", "FUJIS", 
        "FUMED", "FUMER", "FUMES", "FUMET", "FUNDI", "FUNDS", "FUNDY", "FUNGI", "FUNGO", "FUNGS", "FUNKS", "FUNKY", 
        "FURAL", "FURAN", "FURCA", "FURLS", "FUROL", "FURTH", "FURZE", "FURZY", "FUSED", "FUSEL", "FUSIL", "FUSTY", 
        "FUTON", "FUZED", "FUZES", "FUZIL", "FYCES", "FYKED", "FYKES", "FYLES", "FYRDS"
    ],
    "G": [
        "GABLE", "GADES", "GADIS", "GADJE", "GADJO", "GADSO", "GAIDS", "GAILY", "GAINS", "GAIRS", "GAITS", "GAJOS", 
        "GALES", "GALOP", "GALUT", "GALVO", "GAMBE", "GAMBO", "GAMBS", "GAMED", "GAMER", "GAMES", "GAMEY", "GAMIC", 
        "GAMIN", "GAMPS", "GAMUT", "GANCH", "GANDY", "GANEF", "GANEV", "GANOF", "GANTS", "GAOLS", "GAPED", "GAPER", 
        "GAPES", "GAPOS", "GARBE", "GARBO", "GARBS", "GARIS", "GARNI", "GARTH", "GARUM", "GASPY", "GATED", "GATER", 
        "GATES", "GATHS", "GATOR", "GAUCY", "GAUDS", "GAUDY", "GAUJE", "GAULT", "GAUMS", "GAUMY", "GAUNT", "GAUPS", 
        "GAURS", "GAUZE", "GAUZY", "GAVEL", "GAVOT", "GAWCY", "GAWDS", "GAWKS", "GAWKY", "GAWPS", "GAWSY", "GAYER", 
        "GAZED", "GAZER", "GAZES", "GAZON", "GEALS", "GEANS", "GEARS", "GEATS", "GEBUR", "GECKO", "GECKS", "GEIST", 
        "GEITS", "GELDS", "GELID", "GELTS", "GEMOT", "GENAL", "GENAS", "GENIC", "GENIP", "GENOA", "GENOM", "GENRO", 
        "GENTS", "GENTY", "GENUA", "GENUS", "GEOID", "GERAH", "GERMS", "GERMY", "GETAS", "GETUP", "GEUMS", "GEYAN", 
        "GHAST", "GHATS", "GHAUT", "GHAZI", "GHEST", "GHOST", "GHOUL", "GIANT", "GIBED", "GIBEL", "GIBER", "GIBES", 
        "GIBUS", "GIFTS", "GILAS", "GILDS", "GILET", "GILPY", "GILTS", "GIMEL", "GIMPS", "GIMPY", "GINKS", "GINZO", 
        "GIPON", "GIPSY", "GIRDS", "GIRLS", "GIRLY", "GIRNS", "GIRON", "GIROS", "GIRSH", "GIRTH", "GIRTS", "GISMO", 
        "GITES", "GIUST", "GIVED", "GIVEN", "GIVER", "GIVES", "GIZMO", "GLACE", "GLADE", "GLADS", "GLADY", "GLAIK", 
        "GLAIR", "GLAMS", "GLAND", "GLANS", "GLARE", "GLARY", "GLAUM", "GLAUR", "GLAZE", "GLAZY", "GLEAM", "GLEAN", 
        "GLEBA", "GLEBY", "GLEDS", "GLEIS", "GLENS", "GLENT", "GLEYS", "GLIAS", "GLIBS", "GLIDE", "GLIFT", "GLIKE", 
        "GLIME", "GLIMS", "GLINT", "GLISK", "GLITS", "GLITZ", "GLOAM", "GLOAT", "GLOBE", "GLOBI", "GLOBS", "GLOBY", 
        "GLODE", "GLOMS", "GLOPS", "GLORY", "GLOST", "GLOUT", "GLOVE", "GLOWS", "GLOZE", "GLUED", "GLUER", "GLUES", 
        "GLUEY", "GLUME", "GLUMS", "GLUON", "GLUTE", "GLUTS", "GLYPH", "GNARL", "GNARS", "GNASH", "GNATS", "GNAWS", 
        "GNOME", "GNOWS", "GOADS", "GOAFS", "GOALS", "GOARY", "GOATS", "GOATY", "GOBAN", "GODET", "GODLY", "GOELS", 
        "GOERS", "GOETY", "GOFER", "GOIER", "GOLDS", "GOLDY", "GOLEM", "GOLES", "GOLFS", "GOLPE", "GOLPS", "GOMER", 
        "GOMPA", "GONAD", "GONEF", "GONER", "GONIA", "GONIF", "GONKS", "GONYS", "GOPAK", "GOPIK", "GORAL", "GORAS", 
        "GORED", "GORES", "GORIS", "GORMS", "GORMY", "GORPS", "GORSE", "GORSY", "GOSHT", "GOTHS", "GOUKS", "GOURA", 
        "GOURD", "GOUTS", "GOUTY", "GOWAN", "GOWDS", "GOWFS", "GOWKS", "GOWLS", "GOWNS", "GOXES", "GOYIM", "GRABS", 
        "GRACE", "GRADE", "GRADS", "GRAFT", "GRAIL", "GRAIN", "GRAIP", "GRAME", "GRAMP", "GRAMS", "GRAND", "GRANS", 
        "GRANT", "GRAPE", "GRAPH", "GRAPY", "GRASP", "GRATE", "GRAVE", "GRAVS", "GRAVY", "GRAYS", "GRAZE", "GREAT", 
        "GREIN", "GRENS", "GRENZ", "GREWS", "GREYS", "GRICE", "GRIDE", "GRIDS", "GRIEF", "GRIFT", "GRIKE", "GRIME", 
        "GRIMY", "GRIND", "GRINS", "GRIOT", "GRIPE", "GRIPS", "GRIPT", "GRIPY", "GRISE", "GRIST", "GRISY", "GRITH", 
        "GRITS", "GRIZE", "GROAN", "GROAT", "GRODY", "GROIN", "GROKS", "GROMA", "GRONE", "GROPE", "GROSZ", "GROTS", 
        "GROUF", "GROUP", "GROUT", "GROVE", "GROWL", "GROWN", "GROWS", "GRUBS", "GRUED", "GRUEL", "GRUES", "GRUFE", 
        "GRUME", "GRUMP", "GRUNT", "GRYCE", "GRYDE", "GRYKE", "GRYPE", "GRYPT", "GUACO", "GUANO", "GUANS", "GUARD", 
        "GUARS", "GUCKS", "GUCKY", "GUDES", "GUEST", "GUIDE", "GUIDS", "GUILD", "GUILE", "GUILT", "GUIMP", "GUIRO", 
        "GUISE", "GULAR", "GULAS", "GULCH", "GULES", "GULFS", "GULFY", "GULPH", "GULPS", "GULPY", "GUMBO", "GUMPS", 
        "GUNDY", "GUNKS", "GUNKY", "GURLS", "GURLY", "GURNS", "GURSH", "GUSHY", "GUSLA", "GUSLE", "GUSLI", "GUSTO", 
        "GUSTY", "GUTSY", "GUYED", "GUYLE", "GUYOT", "GUYSE", "GWINE", "GYALS", "GYBED", "GYBES", "GYELD", "GYMPS", 
        "GYNAE", "GYNIE", "GYOZA", "GYRAL", "GYRED", "GYRES", "GYRON", "GYROS", "GYRUS", "GYTES", "GYVED", "GYVES"
    ],
    "H": [
        "HABIT", "HABLE", "HABUS", "HACEK", "HACKS", "HADES", "HADJI", "HADST", "HAEMS", "HAETS", "HAFIZ", "HAFTS", 
        "HAICK", "HAIKS", "HAIKU", "HAILS", "HAILY", "HAINS", "HAINT", "HAIRS", "HAIRY", "HAJES", "HAJIS", "HAKES", 
        "HAKIM", "HAKUS", "HALED", "HALER", "HALES", "HALFS", "HALID", "HALMS", "HALON", "HALOS", "HALSE", "HALTS", 
        "HALVE", "HAMED", "HAMES", "HANCE", "HANDS", "HANDY", "HANGI", "HANGS", "HANKS", "HANKY", "HANSE", "HANTS", 
        "HAOLE", "HAPLY", "HAPUS", "HARDS", "HARDY", "HARED", "HAREM", "HARES", "HARIM", "HARKS", "HARLS", "HARMS", 
        "HARNS", "HAROS", "HARPS", "HARPY", "HARTS", "HASTE", "HASTY", "HATED", "HATER", "HATES", "HAUDS", "HAUFS", 
        "HAULD", "HAULM", "HAULS", "HAULT", "HAUNT", "HAUSE", "HAUTE", "HAVEN", "HAVER", "HAVES", "HAVOC", "HAWED", 
        "HAWKS", "HAWMS", "HAWSE", "HAYED", "HAYER", "HAYLE", "HAZED", "HAZEL", "HAZER", "HAZES", "HEADS", "HEADY", 
        "HEALD", "HEALS", "HEAPS", "HEAPY", "HEARD", "HEARS", "HEART", "HEAST", "HEATS", "HEAVY", "HECKS", "HEDGY", 
        "HEFTS", "HEFTY", "HEIDS", "HEILS", "HEIRS", "HEIST", "HEJAB", "HEJRA", "HELIO", "HELIX", "HELMS", "HELOS", 
        "HELOT", "HELPS", "HEMAL", "HEMIC", "HEMIN", "HEMPS", "HEMPY", "HENDS", "HENRY", "HENTS", "HEPAR", "HERBS", 
        "HERBY", "HERDS", "HERLS", "HERMA", "HERMS", "HERNS", "HERON", "HEROS", "HERTZ", "HEXAD", "HEXYL", "HIANT", 
        "HICKS", "HIDER", "HIDES", "HIEMS", "HIJAB", "HIJRA", "HIKED", "HIKER", "HIKES", "HILAR", "HILTS", "HILUM", 
        "HILUS", "HIMBO", "HINAU", "HINDS", "HINGE", "HINGS", "HINKY", "HINTS", "HIPLY", "HIRED", "HIRES", "HIVED", 
        "HIVER", "HIVES", "HIZEN", "HOAED", "HOAGY", "HOARD", "HOARS", "HOARY", "HOAST", "HOCKS", "HOCUS", "HODJA", 
        "HOERS", "HOGAN", "HOGEN", "HOICK", "HOIKS", "HOING", "HOISE", "HOIST", "HOKED", "HOKES", "HOKEY", "HOKIS", 
        "HOKUM", "HOLDS", "HOLED", "HOLES", "HOLEY", "HOLKS", "HOLMS", "HOLTS", "HOMAS", "HOMED", "HOMER", "HOMES", 
        "HOMEY", "HOMIE", "HONDA", "HONDS", "HONED", "HONER", "HONES", "HONEY", "HONGI", "HONGS", "HONKS", "HONKY", 
        "HOPED", "HOPER", "HOPES", "HORAL", "HORAS", "HORDE", "HORIS", "HORME", "HORNS", "HORNY", "HORSE", "HORST", 
        "HORSY", "HOSED", "HOSEL", "HOSEN", "HOSER", "HOSEY", "HOSTA", "HOTEL", "HOTEN", "HOTLY", "HOUFS", "HOUND", 
        "HOURI", "HOURS", "HOUSE", "HOUTS", "HOVEA", "HOVED", "HOVEL", "HOVEN", "HOVER", "HOVES", "HOWBE", "HOWDY", 
        "HOWES", "HOWFS", "HOWKS", "HOWLS", "HOWRE", "HOXED", "HOXES", "HOYAS", "HOYED", "HOYLE", "HUCKS", "HUDNA", 
        "HUERS", "HUGER", "HUIAS", "HULAS", "HULES", "HULKS", "HULKY", "HUMAN", "HUMAS", "HUMFS", "HUMIC", "HUMID", 
        "HUMOR", "HUMPS", "HUMPY", "HUNKS", "HUNKY", "HUNTS", "HURDS", "HURLS", "HURLY", "HURST", "HURTS", "HUSKY", 
        "HUTIA", "HWYLS", "HYDRA", "HYDRO", "HYENA", "HYENS", "HYING", "HYKES", "HYLAS", "HYLEG", "HYLES", "HYLIC", 
        "HYMEN", "HYMNS", "HYNDE", "HYOID", "HYPED", "HYPER", "HYPES", "HYPOS", "HYRAX", "HYSON"
    ],
    "I": [
        "IAMBS", "ICERS", "ICHED", "ICHES", "ICHOR", "ICKER", "ICKLE", "ICONS", "ICTAL", "ICTUS", "IDANT", "IDEAL", 
        "IDEAS", "IDENT", "IDLER", "IDLES", "IDOLA", "IDOLS", "IDYLS", "IFTAR", "IGAPO", "IGLUS", "IHRAM", "IKANS", 
        "IKATS", "IKONS", "ILEAC", "ILEUM", "ILEUS", "IMAGE", "IMAGO", "IMBAR", "IMBED", "IMBUE", "IMPED", "IMPEL", 
        "IMPLY", "IMPOT", "IMSHY", "INAPT", "INARM", "INBYE", "INCLE", "INCOG", "INCUR", "INCUS", "INCUT", "INDEW", 
        "INDEX", "INDOL", "INDOW", "INDUE", "INEPT", "INERM", "INERT", "INFER", "INFOS", "INFRA", "INGLE", "INGOT", 
        "INKED", "INKER", "INKLE", "INLAY", "INLET", "INORB", "INPUT", "INSET", "INTEL", "INTER", "INTRA", "INTRO", 
        "INULA", "INURE", "INUST", "INVAR", "IOTAS", "IRADE", "IRATE", "IRKED", "IRONE", "IRONS", "IRONY", "ISLED", 
        "ISLET", "ISNAE", "ISTLE", "ITCHY", "ITEMS", "ITHER", "IVORY", "IXORA", "IXTLE", "IZARD", "IZARS"
    ],
    "J": [
        "JABOT", "JACKS", "JACKY", "JADES", "JAGER", "JAGIR", "JAILS", "JAKES", "JAKEY", "JALOP", "JAMBE", "JAMBO", 
        "JAMBS", "JAMBU", "JAMES", "JANES", "JANTY", "JAPED", "JAPER", "JAPES", "JARKS", "JARLS", "JARPS", "JARUL", 
        "JASEY", "JASPE", "JATOS", "JAUKS", "JAUNT", "JAUPS", "JAVEL", "JAWED", "JAXIE", "JEANS", "JEATS", "JEDIS", 
        "JEHAD", "JEHUS", "JELAB", "JERID", "JERKS", "JERKY", "JETON", "JIAOS", "JIBED", "JIBER", "JIBES", "JIGOT", 
        "JIHAD", "JILTS", "JIMPY", "JINGO", "JINKS", "JIRDS", "JIRGA", "JIVED", "JIVER", "JIVES", "JIVEY", "JOBED", 
        "JOBES", "JOCKS", "JODEL", "JOEYS", "JOHNS", "JOINS", "JOINT", "JOIST", "JOKED", "JOKER", "JOKES", "JOKEY", 
        "JOLED", "JOLES", "JOLTS", "JOLTY", "JONES", "JONGS", "JONTY", "JORAM", "JORUM", "JOTAS", "JOTUN", "JOUAL", 
        "JOUGS", "JOUKS", "JOULE", "JOURS", "JOUST", "JOWAR", "JOWED", "JOWLS", "JOWLY", "JOYED", "JUBAS", "JUBES", 
        "JUCOS", "JUDAS", "JUDGE", "JUDOS", "JUGAL", "JUICE", "JUICY", "JUKED", "JUKES", "JULEP", "JUMAR", "JUMBO", 
        "JUMBY", "JUMPS", "JUMPY", "JUNCO", "JUNKS", "JUNKY", "JUNTA", "JUNTO", "JUPES", "JUPON", "JURAL", "JURAT", 
        "JUREL", "JUTES", "JUVES"
    ],
    "K": [
        "KADES", "KADIS", "KAFIR", "KAGOS", "KAGUS", "KAIDS", "KAIES", "KAIFS", "KAILS", "KAIMS", "KAING", "KAINS", 
        "KALES", "KALIF", "KALIS", "KAMES", "KAMIS", "KANDY", "KANEH", "KANES", "KANGS", "KANJI", "KANTS", "KANZU", 
        "KAONS", "KAPHS", "KAPUT", "KARNS", "KARST", "KARSY", "KARTS", "KARZY", "KASME", "KATIS", "KAUGH", "KAURI", 
        "KAURY", "KAWED", "KAYLE", "KAYOS", "KAZIS", "KBARS", "KEBAR", "KEDGY", "KEFIR", "KEHUA", "KEIRS", "KELIM", 
        "KELPS", "KELPY", "KELTS", "KELTY", "KEMBO", "KEMBS", "KEMPS", "KEMPT", "KEMPY", "KENAF", "KENCH", "KENDO", 
        "KENOS", "KENTS", "KEPIS", "KERBS", "KERFS", "KERMA", "KERNS", "KEROS", "KESAR", "KETAS", "KETCH", "KETOL", 
        "KEVIL", "KHADI", "KHAFS", "KHANS", "KHATS", "KHAZI", "KHEDA", "KHETS", "KHOJA", "KHORS", "KHOUM", "KHUDS", 
        "KIANG", "KIBES", "KIBLA", "KIDEL", "KIDGE", "KIEFS", "KIERS", "KIGHT", "KILEY", "KILNS", "KILOS", "KILPS", 
        "KILTS", "KILTY", "KIMBO", "KINAS", "KINDA", "KINDS", "KINDY", "KINES", "KINGS", "KINOS", "KIORE", "KIPES", 
        "KIRBY", "KIRNS", "KISAN", "KITED", "KITER", "KITES", "KITHE", "KITHS", "KIVAS", "KLANG", "KLAPS", "KLIEG", 
        "KLONG", "KLUGE", "KLUTZ", "KNAGS", "KNAPS", "KNARL", "KNARS", "KNAUR", "KNAVE", "KNAWE", "KNEAD", "KNELT", 
        "KNIFE", "KNISH", "KNITS", "KNIVE", "KNOBS", "KNOPS", "KNOSP", "KNOTS", "KNOUT", "KNOWE", "KNOWS", "KNUBS", 
        "KNURL", "KNURS", "KNUTS", "KOANS", "KOAPS", "KOBAN", "KOELS", "KOFTA", "KOHAS", "KOHLS", "KOINE", "KOJIS", 
        "KOLAS", "KOMBU", "KONBU", "KOPHS", "KOPJE", "KORAI", "KORAS", "KORAT", "KORES", "KORMA", "KORUN", "KORUS", 
        "KOTCH", "KOURA", "KRABS", "KRAFT", "KRAIT", "KRANG", "KRANS", "KRANZ", "KRAUT", "KRENG", "KRONA", "KRONE", 
        "KRUBI", "KUDOS", "KUFIS", "KUGEL", "KUIAS", "KULAN", "KULAS", "KULFI", "KUMYS", "KURIS", "KURTA", "KUTAS", 
        "KUTCH", "KUTIS", "KWELA", "KYANG", "KYARS", "KYATS", "KYBOS", "KYDST", "KYLES", "KYLIE", "KYLIN", "KYLIX", 
        "KYLOE", "KYNDE", "KYNDS", "KYPES", "KYRIE", "KYTES", "KYTHE"
    ],
    "L": [
        "LABIS", "LABOR", "LACED", "LACER", "LACES", "LACET", "LACEY", "LACKS", "LADEN", "LADER", "LADES", "LAERS", 
        "LAEVO", "LAGER", "LAICH", "LAICS", "LAIDS", "LAIGH", "LAIKS", "LAIRD", "LAIRS", "LAIRY", "LAITH", "LAITY", 
        "LAKED", "LAKER", "LAKES", "LAKHS", "LAKIN", "LAMBS", "LAMBY", "LAMED", "LAMER", "LAMES", "LAMPS", "LANCE", 
        "LANCH", "LANDE", "LANDS", "LANES", "LANKS", "LANKY", "LANTS", "LAPIN", "LAPIS", "LAPJE", "LAPSE", "LARCH", 
        "LARDS", "LARDY", "LARES", "LARGE", "LARGO", "LARIS", "LARKS", "LARKY", "LARNS", "LARUM", "LASED", "LASER", 
        "LATCH", "LATED", "LATEN", "LATER", "LATEX", "LATHE", "LATHI", "LATHS", "LATHY", "LATKE", "LAUCH", "LAUDS", 
        "LAUFS", "LAUGH", "LAUND", "LAVED", "LAVER", "LAVES", "LAWED", "LAWER", "LAWIN", "LAWKS", "LAWNS", "LAWNY", 
        "LAXER", "LAXES", "LAYED", "LAYER", "LAYIN", "LAYUP", "LAZED", "LAZES", "LAZOS", "LEACH", "LEADS", "LEADY", 
        "LEAFS", "LEAFY", "LEAKS", "LEAKY", "LEAMS", "LEANS", "LEANT", "LEANY", "LEAPS", "LEAPT", "LEARN", "LEARS", 
        "LEARY", "LEASH", "LEAST", "LEATS", "LEAVY", "LEDGY", "LEDUM", "LEFTS", "LEFTY", "LEGIT", "LEHRS", "LEHUA", 
        "LEIRS", "LEISH", "LEMAN", "LEMON", "LEMUR", "LENDS", "LENGS", "LENIS", "LENOS", "LENTI", "LENTO", "LEPID", 
        "LEPRA", "LEPTA", "LERPS", "LESBO", "LETCH", "LETUP", "LEUCH", "LEUCO", "LEUDS", "LEUGH", "LEVIN", "LEVIS", 
        "LEWIS", "LEXIS", "LIANE", "LIANG", "LIARD", "LIARS", "LIART", "LIBER", "LIBRA", "LICHT", "LICKS", "LIDAR", 
        "LIDOS", "LIEFS", "LIENS", "LIERS", "LIEUS", "LIFER", "LIFES", "LIFTS", "LIGAN", "LIGER", "LIGHT", "LIGNE", 
        "LIKED", "LIKEN", "LIKER", "LIKES", "LIMAN", "LIMAS", "LIMAX", "LIMBA", "LIMBO", "LIMBS", "LIMBY", "LIMED", 
        "LIMEN", "LIMES", "LIMEY", "LIMNS", "LIMOS", "LIMPA", "LIMPS", "LINAC", "LINCH", "LINDS", "LINDY", "LINED", 
        "LINER", "LINES", "LINEY", "LINGA", "LINGO", "LINGS", "LINGY", "LINKS", "LINKY", "LINOS", "LINTS", "LINTY", 
        "LINUM", "LINUX", "LIONS", "LIPOS", "LIRAS", "LIRKS", "LIROT", "LITAS", "LITED", "LITER", "LITES", "LITHE", 
        "LITHO", "LITHS", "LITRE", "LIVED", "LIVEN", "LIVER", "LIVES", "LIVOR", "LIVRE", "LOACH", "LOADS", "LOAFS", 
        "LOAMS", "LOAMY", "LOANS", "LOAST", "LOATH", "LOAVE", "LOBAR", "LOBED", "LOBES", "LOBUS", "LOCHS", "LOCKS", 
        "LOCUM", "LOCUS", "LODEN", "LODES", "LODGE", "LOFTS", "LOFTY", "LOGAN", "LOGES", "LOGIA", "LOGIC", "LOGIE", 
        "LOGIN", "LOHAN", "LOIDS", "LOINS", "LOIPE", "LOIRS", "LOKES", "LOMAS", "LOMED", "LOMES", "LONER", "LONGA", 
        "LONGE", "LONGS", "LOPED", "LOPER", "LOPES", "LORAN", "LORDS", "LORDY", "LORES", "LORIC", "LORIS", "LOSED", 
        "LOSEN", "LOSER", "LOTAH", "LOTAS", "LOTES", "LOTIC", "LOTUS", "LOUED", "LOUGH", "LOUIE", "LOUIS", "LOUMA", 
        "LOUND", "LOUNS", "LOUPE", "LOUPS", "LOURE", "LOURS", "LOURY", "LOUSE", "LOUSY", "LOUTS", "LOVAT", "LOVED", 
        "LOVER", "LOVES", "LOVEY", "LOWAN", "LOWED", "LOWER", "LOWES", "LOWND", "LOWNE", "LOWNS", "LOWPS", "LOWRY", 
        "LOWSE", "LOWTS", "LOXED", "LOXES", "LOZEN", "LUACH", "LUBED", "LUBES", "LUBRA", "LUCES", "LUCID", "LUCKS", 
        "LUCKY", "LUCRE", "LUDES", "LUDIC", "LUDOS", "LUGED", "LUGER", "LUGES", "LUMAS", "LUMEN", "LUMPS", "LUMPY", 
        "LUNAR", "LUNAS", "LUNCH", "LUNES", "LUNET", "LUNGE", "LUNGI", "LUNGS", "LUNKS", "LUNTS", "LUPIN", "LURCH", 
        "LURED", "LURES", "LUREX", "LURGI", "LURGY", "LURID", "LURKS", "LURVE", "LUSER", "LUSHY", "LUSTY", "LUTEA", 
        "LUTED", "LUTER", "LUTES", "LUXES", "LWEIS", "LYAMS", "LYARD", "LYART", "LYASE", "LYCEA", "LYCRA", "LYING", 
        "LYMES", "LYMPH", "LYNCH", "LYNES", "LYRES", "LYRIC", "LYSED", "LYSIN", "LYTED", "LYTES", "LYTHE", "LYTIC" 
    ],
    "M": [
        "MABES", "MACED", "MACER", "MACES", "MACHE", "MACHI", "MACHO", "MACHS", "MACKS", "MACLE", "MACON", "MACRO", 
        "MADGE", "MADLY", "MADRE", "MAFIC", "MAGES", "MAGIC", "MAGOT", "MAGUS", "MAHOE", "MAIDS", "MAIKO", "MAIKS", 
        "MAILE", "MAILS", "MAINS", "MAIRE", "MAIRS", "MAISE", "MAIST", "MAIZE", "MAJOR", "MAKER", "MAKES", "MAKIS", 
        "MAKOS", "MALES", "MALIC", "MALIK", "MALIS", "MALTS", "MALTY", "MANDI", "MANED", "MANEH", "MANES", "MANET", 
        "MANGE", "MANGO", "MANGS", "MANGY", "MANIC", "MANIS", "MANKY", "MANLY", "MANOR", "MANOS", "MANSE", "MANTO", 
        "MANTY", "MANUL", "MANUS", "MAPLE", "MAQUI", "MARCH", "MARCS", "MARDY", "MARES", "MARGE", "MARGS", "MARID", 
        "MARKS", "MARLE", "MARLS", "MARLY", "MARON", "MARSE", "MARSH", "MARTS", "MARVY", "MASED", "MASER", "MASHY", 
        "MASON", "MASTY", "MATCH", "MATED", "MATER", "MATES", "MATEY", "MATHS", "MATIN", "MATLO", "MATZO", "MAUBY", 
        "MAUDS", "MAULS", "MAUND", "MAURI", "MAUTS", "MAUVE", "MAVEN", "MAVIE", "MAVIN", "MAVIS", "MAWED", "MAWKS", 
        "MAWKY", "MAWRS", "MAXED", "MAXES", "MAXIS", "MAYBE", "MAYED", "MAYOR", "MAYOS", "MAYST", "MAZED", "MAZER", 
        "MAZES", "MAZEY", "MAZUT", "MBIRA", "MEADS", "MEALS", "MEALY", "MEANS", "MEANT", "MEANY", "MEATH", "MEATS", 
        "MEATY", "MEBOS", "MECKS", "MEDAL", "MEDIA", "MEDIC", "MEINS", "MEINT", "MEINY", "MEITH", "MELAS", "MELDS", 
        "MELIC", "MELIK", "MELON", "MELTS", "MELTY", "MENAD", "MENDS", "MENGS", "MENSA", "MENSH", "MENTA", "MENTO", 
        "MENUS", "MEOUS", "MEOWS", "MERCH", "MERCS", "MERCY", "MERIL", "MERIS", "MERIT", "MERKS", "MERLS", "MESAL", 
        "MESHY", "MESIC", "MESON", "MESTO", "METAL", "METHO", "METHS", "METIC", "METIF", "METIS", "METOL", "METRO", 
        "MEWLS", "MEYNT", "MIAOU", "MIAOW", "MIAUL", "MICAS", "MICHE", "MICHT", "MICKS", "MICKY", "MICOS", "MICRA", 
        "MICRO", "MIDGE", "MIDGY", "MIDST", "MIENS", "MIFTY", "MIGHT", "MIKED", "MIKES", "MIKRA", "MILCH", "MILDS", 
        "MILER", "MILES", "MILKO", "MILKS", "MILKY", "MILOR", "MILOS", "MILPA", "MILTS", "MILTY", "MILTZ", "MINAE", 
        "MINAR", "MINAS", "MINCE", "MINCY", "MINDS", "MINED", "MINER", "MINES", "MINGE", "MINGS", "MINGY", "MINKE", 
        "MINKS", "MINOR", "MINOS", "MINTS", "MINTY", "MINUS", "MIRED", "MIRES", "MIREX", "MIRKS", "MIRKY", "MIRLY", 
        "MIRTH", "MIRVS", "MIRZA", "MISCH", "MISDO", "MISER", "MISGO", "MISTY", "MITCH", "MITER", "MITES", "MITRE", 
        "MIXED", "MIXEN", "MIXER", "MIXES", "MIXTE", "MIXUP", "MIZEN", "MOANS", "MOATS", "MOBES", "MOBIE", "MOBLE", 
        "MOCHA", "MOCHS", "MOCHY", "MOCKS", "MODAL", "MODEL", "MODER", "MODES", "MODGE", "MODUS", "MOERS", "MOGUL", 
        "MOHEL", "MOHRS", "MOHUA", "MOHUR", "MOILS", "MOIRA", "MOIRE", "MOIST", "MOITS", "MOKES", "MOKIS", "MOLAR", 
        "MOLAS", "MOLDS", "MOLDY", "MOLES", "MOLTS", "MONAD", "MONAL", "MONAS", "MONDE", "MONER", "MONEY", "MONGS", 
        "MONIE", "MONKS", "MONTE", "MONTH", "MONTY", "MOPED", "MOPER", "MOPES", "MOPEY", "MOPSY", "MOPUS", "MORAE", 
        "MORAL", "MORAS", "MORAT", "MORAY", "MOREL", "MORES", "MORIA", "MORNE", "MORNS", "MORPH", "MORSE", "MORTS", 
        "MOSED", "MOSEY", "MOSTE", "MOTED", "MOTEL", "MOTEN", "MOTES", "MOTEY", "MOTHS", "MOTHY", "MOTIF", "MOTIS", 
        "MOTUS", "MOTZA", "MOUCH", "MOUES", "MOULD", "MOULS", "MOULT", "MOUND", "MOUNT", "MOUPS", "MOURN", "MOUSE", 
        "MOUST", "MOUSY", "MOUTH", "MOVED", "MOVER", "MOVES", "MOVIE", "MOWAS", "MOWED", "MOWER", "MOWRA", "MOXAS", 
        "MOXIE", "MOYAS", "MOYLE", "MOYLS", "MOZED", "MOZES", "MPRET", "MUCHO", "MUCID", "MUCIN", "MUCKS", "MUCKY", 
        "MUCOR", "MUCRO", "MUDGE", "MUDIR", "MUDRA", "MUFTI", "MUHLY", "MUIDS", "MUILS", "MUIRS", "MUIST", "MUJIK", 
        "MULCH", "MULCT", "MULED", "MULES", "MULEY", "MULGA", "MULSE", "MULSH", "MUNCH", "MUNGA", "MUNGO", "MUNGS", 
        "MUNIS", "MUNTS", "MUONS", "MURAL", "MURAS", "MURED", "MURES", "MUREX", "MURID", "MURKS", "MURKY", "MURLS", 
        "MURLY", "MURTI", "MURVA", "MUSAR", "MUSCA", "MUSED", "MUSER", "MUSET", "MUSHA", "MUSHY", "MUSIC", "MUSIT", 
        "MUSKY", "MUSTH", "MUSTY", "MUTCH", "MUTED", "MUTER", "MUTES", "MUTHA", "MUTIS", "MUTON", "MUXED", "MUXES", 
        "MVULE", "MYLAR", "MYNAH", "MYNAS", "MYOID", "MYOPE", "MYOPS", "MYSID", "MYTHI", "MYTHS", "MYXOS"
    ],
    "N": [
        "NABES", "NABIS", "NABKS", "NACHE", "NACHO", "NACRE", "NADIR", "NAEVI", "NAGOR", "NAIFS", "NAIKS", "NAILS", 
        "NAIRU", "NAIVE", "NAKED", "NAKER", "NALED", "NAMED", "NAMER", "NAMES", "NAPED", "NAPES", "NARCO", "NARCS", 
        "NARDS", "NARES", "NARIC", "NARIS", "NARKS", "NARKY", "NASHI", "NASTY", "NATCH", "NATES", "NATIS", "NAUCH", 
        "NAVEL", "NAVES", "NAVEW", "NAZES", "NAZIR", "NAZIS", "NEALS", "NEAPS", "NEARS", "NEATH", "NEATS", "NECKS", 
        "NEGRO", "NEGUS", "NEIFS", "NEIGH", "NEIST", "NELIS", "NEMAS", "NEMPT", "NEPIT", "NERAL", "NERDS", "NERDY", 
        "NERKA", "NERKS", "NEROL", "NERTS", "NERTZ", "NERVY", "NETOP", "NEUKS", "NEUMS", "NEVUS", "NEWLY", "NEWSY", 
        "NEWTS", "NEXTS", "NEXUS", "NGAIO", "NGATI", "NGOMA", "NICAD", "NICER", "NICHE", "NICHT", "NICKS", "NICKY", 
        "NICOL", "NIDAL", "NIDES", "NIDOR", "NIDUS", "NIEFS", "NIFES", "NIFTY", "NIGER", "NIGHS", "NIGHT", "NIKAU", 
        "NIMBS", "NIMPS", "NIPAS", "NIQAB", "NIRLS", "NIRLY", "NITER", "NITES", "NITRE", "NITRO", "NITRY", "NIVAL", 
        "NIXED", "NIXER", "NIXES", "NIZAM", "NKOSI", "NOAHS", "NOBLE", "NOBLY", "NOCKS", "NODAL", "NODES", "NODUS", 
        "NOELS", "NOILS", "NOILY", "NOIRS", "NOISE", "NOISY", "NOLES", "NOMAD", "NOMAS", "NOMES", "NOMIC", "NOPAL", 
        "NORIA", "NORIS", "NORKS", "NORMA", "NORMS", "NORTH", "NOSED", "NOSER", "NOSEY", "NOTAL", "NOTCH", "NOTED", 
        "NOTER", "NOTES", "NOTUM", "NOULD", "NOULE", "NOULS", "NOUPS", "NOVAE", "NOVAS", "NOVEL", "NOVUM", "NOWAY", 
        "NOWED", "NOWLS", "NOWTS", "NOWTY", "NOXAL", "NOXES", "NOYAU", "NOYED", "NOYES", "NUBIA", "NUCHA", "NUDER", 
        "NUDES", "NUDGE", "NUDIE", "NUDZH", "NUGAE", "NUKED", "NUKES", "NUMBS", "NURDS", "NURDY", "NURLS", "NURSE", 
        "NUTSO", "NUTSY", "NYMPH"
    ],
    "O": [
        "OAKED", "OAKEN", "OAKER", "OAKUM", "OARED", "OATEN", "OATER", "OATHS", "OAVES", "OBANG", "OBEAH", "OBELI", 
        "OBEYS", "OBIAS", "OBIED", "OBITS", "OBJET", "OCEAN", "OCHER", "OCHES", "OCHRE", "OCHRY", "OCKER", "OCREA", 
        "OCTAD", "OCTAL", "OCTAN", "OCTAS", "OCTYL", "OCULI", "ODAHS", "ODALS", "ODEUM", "ODISM", "ODIST", "ODIUM", 
        "ODYLE", "ODYLS", "OFAYS", "OFLAG", "OFTEN", "OFTER", "OGAMS", "OGHAM", "OGIVE", "OGLED", "OGLER", "OGLES", 
        "OGMIC", "OGRES", "OHIAS", "OHING", "OHMIC", "OILED", "OILER", "OINKS", "OINTS", "OJIME", "OKAPI", "OKAYS", 
        "OKEHS", "OKRAS", "OKTAS", "OLDEN", "OLDER", "OLDIE", "OLEIC", "OLEIN", "OLENT", "OLEUM", "OLIVE", "OLPAE", 
        "OLPES", "OMBER", "OMBRE", "OMBUS", "OMEGA", "OMENS", "OMERS", "OMITS", "OMLAH", "OMRAH", "ONCER", "ONCES", 
        "ONCET", "ONCUS", "ONELY", "ONERS", "ONERY", "ONIUM", "ONKUS", "ONLAY", "ONSET", "ONTIC", "OPAHS", "OPALS", 
        "OPENS", "OPERA", "OPINE", "OPING", "OPIUM", "OPSIN", "OPTED", "OPTER", "OPTIC", "ORACH", "ORACY", "ORALS", 
        "ORANG", "ORANT", "ORATE", "ORBED", "ORBIT", "ORCAS", "ORCIN", "OREAD", "ORFES", "ORGAN", "ORGIA", "ORGIC", 
        "ORGUE", "ORIEL", "ORIXA", "ORLES", "ORNIS", "ORPIN", "ORVAL", "OSCAR", "OSHAC", "OSIER", "OSMIC", "OSTIA", 
        "OTAKU", "OTARY", "OTHER", "OUBIT", "OUCHT", "OUGHT", "OUIJA", "OULKS", "OUMAS", "OUNCE", "OUNDY", "OUPAS", 
        "OUPED", "OUPHE", "OUPHS", "OURIE", "OUSEL", "OUTBY", "OUTED", "OUTER", "OUTRE", "OUZEL", "OVALS", "OVARY", 
        "OVATE", "OVELS", "OVENS", "OVERS", "OVERT", "OVINE", "OVIST", "OVULE", "OWCHE", "OWING", "OWLED", "OWLER", 
        "OWLET", "OWNED", "OWNER", "OWRES", "OWRIE", "OWSEN", "OXERS", "OXIDE", "OXIDS", "OXIME", "OXIMS", "OXLIP", 
        "OXTER", "OYERS", "OZEKI"
    ],
    "P": [
        "PACED", "PACER", "PACES", "PACEY", "PACKS", "PACOS", "PACTS", "PADIS", "PADLE", "PADRE", "PADRI", "PAEON", 
        "PAGED", "PAGER", "PAGES", "PAGLE", "PAGOD", "PAGRI", "PAIKS", "PAILS", "PAINS", "PAINT", "PAIRE", "PAIRS", 
        "PAISE", "PALED", "PALER", "PALES", "PALET", "PALKI", "PALMS", "PALMY", "PALSY", "PANCE", "PANDS", "PANDY", 
        "PANED", "PANEL", "PANES", "PANGS", "PANIC", "PANIM", "PANSY", "PANTO", "PANTS", "PANTY", "PAOLI", "PARCH", 
        "PARDI", "PARDS", "PARDY", "PARED", "PAREO", "PARES", "PAREU", "PAREV", "PARGE", "PARGO", "PARIS", "PARKI", 
        "PARKS", "PARKY", "PARLE", "PARLY", "PAROL", "PARSE", "PARTI", "PARTS", "PARTY", "PARVE", "PARVO", "PASEO", 
        "PASHM", "PASTE", "PASTY", "PATCH", "PATED", "PATEN", "PATER", "PATES", "PATHS", "PATIN", "PATIO", "PATLY", 
        "PATSY", "PATUS", "PAULS", "PAUSE", "PAVED", "PAVEN", "PAVER", "PAVES", "PAVID", "PAVIN", "PAVIS", "PAWED", 
        "PAWER", "PAWKS", "PAWKY", "PAWLS", "PAWNS", "PAXES", "PAYED", "PAYER", "PAYOR", "PAYSD", "PEACH", "PEAGS", 
        "PEAKS", "PEAKY", "PEALS", "PEANS", "PEARL", "PEARS", "PEART", "PEATS", "PEATY", "PEAVY", "PEBAS", "PECAN", 
        "PECHS", "PECKS", "PECKY", "PEDAL", "PEDRO", "PEGHS", "PEINS", "PEKAN", "PEKIN", "PELAS", "PELFS", "PELMA", 
        "PELON", "PELTA", "PELTS", "PENAL", "PENDS", "PENDU", "PENGO", "PENIS", "PENKS", "PENTS", "PEONS", "PEONY", 
        "PERAI", "PERCH", "PERDU", "PERDY", "PERIL", "PERIS", "PERKS", "PERKY", "PERMS", "PERNS", "PERST", "PERTS", 
        "PERVS", "PESKY", "PESTO", "PESTY", "PETAL", "PETAR", "PEWIT", "PHAGE", "PHANG", "PHARE", "PHASE", "PHEON", 
        "PHIAL", "PHLOX", "PHOCA", "PHONE", "PHONS", "PHONY", "PHOTS", "PHUTS", "PHYLA", "PHYLE", "PIANO", "PIANS", 
        "PIBAL", "PICAL", "PICAS", "PICKS", "PICKY", "PICOT", "PICRA", "PICUL", "PIEND", "PIERS", "PIERT", "PIETA", 
        "PIETS", "PIETY", "PIEZO", "PIGHT", "PIGMY", "PIKAS", "PIKAU", "PIKED", "PIKER", "PIKES", "PIKEY", "PIKUL", 
        "PILAF", "PILAO", "PILAR", "PILAU", "PILAW", "PILCH", "PILEA", "PILED", "PILER", "PILES", "PILOT", "PILOW", 
        "PILUM", "PILUS", "PIMAS", "PINAS", "PINCH", "PINED", "PINES", "PINEY", "PINGO", "PINGS", "PINKO", "PINKS", 
        "PINKY", "PINOT", "PINTA", "PINTO", "PINTS", "PIONS", "PIONY", "PIOUS", "PIOYE", "PIOYS", "PIQUE", "PIRLS", 
        "PIRNS", "PIROG", "PISCO", "PISKY", "PISTE", "PITAS", "PITCH", "PITHS", "PITHY", "PITON", "PIUMS", "PIVOT", 
        "PIXEL", "PIXES", "PIZED", "PIZES", "PLACE", "PLACK", "PLAGE", "PLAID", "PLAIN", "PLAIT", "PLANE", "PLANK", 
        "PLANS", "PLANT", "PLASH", "PLASM", "PLAST", "PLATE", "PLATS", "PLATY", "PLAYS", "PLEAD", "PLEAS", "PLEAT", 
        "PLEBS", "PLENA", "PLEON", "PLESH", "PLEWS", "PLICA", "PLIED", "PLIER", "PLIES", "PLIMS", "PLING", "PLINK", 
        "PLOAT", "PLODS", "PLONG", "PLONK", "PLOTS", "PLOTZ", "PLOUK", "PLOWS", "PLOYS", "PLUCK", "PLUES", "PLUGS", 
        "PLUMB", "PLUME", "PLUMS", "PLUMY", "PLUNK", "PLUSH", "PLYER", "POACH", "POAKE", "POCKS", "POCKY", "PODAL", 
        "PODEX", "PODGE", "PODGY", "PODIA", "POEMS", "POESY", "POETS", "POGEY", "POILU", "POIND", "POINT", "POISE", 
        "POKAL", "POKED", "POKER", "POKES", "POKEY", "POKIE", "POLAR", "POLED", "POLER", "POLES", "POLEY", "POLIS", 
        "POLJE", "POLKA", "POLKS", "POLTS", "POLYS", "POMBE", "POMES", "PONCE", "PONCY", "PONDS", "PONES", "PONEY", 
        "PONGA", "PONGS", "PONGY", "PONKS", "PONTS", "PONTY", "PONZU", "PORAE", "PORAL", "PORCH", "PORED", "PORES", 
        "PORGE", "PORGY", "PORKS", "PORKY", "PORNS", "PORNY", "PORTA", "PORTS", "PORTY", "POSED", "POSER", "POSEY", 
        "POSIT", "POTAE", "POTCH", "POTED", "POTES", "POTIN", "POTSY", "POUCH", "POUFS", "POUKE", "POUKS", "POULE", 
        "POULT", "POUND", "POURS", "POUTS", "POUTY", "POWAN", "POWER", "POWIN", "POWND", "POWNS", "POWNY", "POWRE", 
        "POXED", "POXES", "POYNT", "POYSE", "PRADS", "PRAHU", "PRAMS", "PRANG", "PRANK", "PRAOS", "PRASE", "PRATE", 
        "PRATS", "PRATY", "PRAUS", "PRAWN", "PRAYS", "PREDY", "PREIF", "PREMS", "PREMY", "PRENT", "PRESA", "PREST", 
        "PREXY", "PREYS", "PRIAL", "PRICE", "PRICK", "PRICY", "PRIDE", "PRIED", "PRIEF", "PRIES", "PRIGS", "PRIMA", 
        "PRIME", "PRIMO", "PRIMS", "PRIMY", "PRINK", "PRINT", "PRION", "PRISE", "PRISM", "PRIVY", "PRIZE", "PROAS", 
        "PROBE", "PROBS", "PRODS", "PROEM", "PROFS", "PROGS", "PROIN", "PROKE", "PROLE", "PROMS", "PRONE", "PRONG", 
        "PRONK", "PROSE", "PROST", "PROSY", "PROUD", "PROUL", "PROVE", "PROWL", "PROWS", "PROXY", "PROYN", "PRUDE", 
        "PRUNE", "PRUNT", "PRUTA", "PRYSE", "PSALM", "PSEUD", "PSHAW", "PSION", "PSOAE", "PSOAI", "PSORA", "PSYCH", 
        "PUBES", "PUBIC", "PUBIS", "PUCAN", "PUCER", "PUCES", "PUCKA", "PUCKS", "PUDGE", "PUDGY", "PUDIC", "PUDOR", 
        "PUDSY", "PUERS", "PUGIL", "PUHAS", "PUJAH", "PUJAS", "PUKED", "PUKER", "PUKES", "PULAO", "PULAS", "PULED", 
        "PULER", "PULES", "PULIK", "PULIS", "PULKA", "PULKS", "PULMO", "PULSE", "PUMAS", "PUMIE", "PUNAS", "PUNCE", 
        "PUNCH", "PUNGA", "PUNGS", "PUNJI", "PUNKA", "PUNKS", "PUNKY", "PUNTO", "PUNTS", "PUNTY", "PURDA", "PURED", 
        "PURES", "PURGE", "PURIM", "PURIN", "PURIS", "PURLS", "PURSE", "PURSY", "PURTY", "PUSHY", "PUSLE", "PUTID", 
        "PUTON", "PUZEL", "PYATS", "PYETS", "PYGAL", "PYINS", "PYLON", "PYNED", "PYNES", "PYOID", "PYOTS", "PYRAL", 
        "PYRAN", "PYRES", "PYREX", "PYRIC", "PYROS", "PYXED", "PYXES", "PYXIE", "PYXIS"
    ],
    "Q": [
        "QADIS", "QAIDS", "QIBLA", "QOPHS", "QORMA", "QUACK", "QUADS", "QUAGS", "QUAIL", "QUAIR", "QUAIS", "QUAKE", 
        "QUAKY", "QUALE", "QUALM", "QUANT", "QUARE", "QUARK", "QUART", "QUASH", "QUASI", "QUATE", "QUATS", "QUAYD", 
        "QUAYS", "QUBIT", "QUEAN", "QUENA", "QUERN", "QUERY", "QUEST", "QUEYN", "QUEYS", "QUICH", "QUICK", "QUIDS", 
        "QUIET", "QUILT", "QUIMS", "QUINA", "QUINE", "QUINO", "QUINS", "QUINT", "QUIPO", "QUIPS", "QUIRE", "QUIRK", 
        "QUIRT", "QUIST", "QUITE", "QUITS", "QUOAD", "QUODS", "QUOIF", "QUOIN", "QUOIT", "QUONK", "QUOPS", "QUOTA", 
        "QUOTE", "QUOTH", "QURSH", "QUYTE"
    ],
    "R": [
        "RABIC", "RABID", "RABIS", "RACED", "RACES", "RACHE", "RACKS", "RACON", "RADGE", "RADIO", "RADIX", "RADON", 
        "RAFTS", "RAGDE", "RAGED", "RAGES", "RAGIS", "RAHED", "RAHUI", "RAIDS", "RAIKS", "RAILE", "RAILS", "RAINE", 
        "RAINS", "RAINY", "RAISE", "RAITS", "RAJES", "RAKED", "RAKES", "RAKIS", "RAKUS", "RALES", "RALPH", "RAMEN", 
        "RAMET", "RAMIE", "RAMIN", "RAMIS", "RAMPS", "RAMUS", "RANCE", "RANCH", "RANDS", "RANDY", "RANGE", "RANGI", 
        "RANGY", "RANID", "RANIS", "RANKE", "RANKS", "RANTS", "RAPED", "RAPES", "RAPHE", "RAPID", "RASED", "RASPY", 
        "RATCH", "RATED", "RATEL", "RATES", "RATHE", "RATHS", "RATIO", "RATOS", "RATUS", "RAUNS", "RAUPO", "RAVED", 
        "RAVEL", "RAVEN", "RAVES", "RAVIN", "RAWIN", "RAWLY", "RAWNS", "RAXED", "RAXES", "RAYED", "RAYLE", "RAYNE", 
        "RAYON", "RAZED", "RAZES", "REACH", "REACT", "READS", "READY", "REAKS", "REALM", "REALO", "REALS", "REAMS", 
        "REAMY", "REANS", "REAPS", "REAST", "REBID", "REBIT", "REBOP", "REBUS", "REBUT", "REBUY", "RECAL", "RECAP", 
        "RECIT", "RECKS", "RECON", "RECTA", "RECTI", "RECTO", "RECUT", "REDAN", "REDIA", "REDIP", "REDLY", "REDON", 
        "REDOS", "REDOX", "REDUB", "REDUX", "REFIT", "REFIX", "REFLY", "REGAL", "REGMA", "REGNA", "REGOS", "REHAB", 
        "REIFS", "REIFY", "REIGN", "REIKS", "REINK", "REINS", "REIST", "REJIG", "REJON", "RELAX", "RELAY", "RELIC", 
        "RELIT", "REMAN", "REMAP", "REMIT", "REMIX", "RENAL", "RENAY", "RENDS", "RENGA", "RENIG", "RENTS", "REOIL", 
        "REPAY", "REPIN", "REPLA", "REPLY", "REPOS", "REPOT", "RESAT", "RESAW", "RESAY", "RESID", "RESIN", "RESIT", 
        "RESOD", "RESOW", "RESTO", "RESTY", "RETAG", "RETAX", "RETCH", "RETIA", "REWAN", "REWAX", "REWIN", "REWON", 
        "REWTH", "RHEAS", "RHEUM", "RHIES", "RHIME", "RHINE", "RHINO", "RHODY", "RHOMB", "RHONE", "RHUMB", "RHYME", 
        "RHYNE", "RHYTA", "RIALS", "RIANT", "RIBAS", "RIBES", "RICED", "RICES", "RICEY", "RICHT", "RICKS", "RIDES", 
        "RIDGE", "RIDGY", "RIELS", "RIEMS", "RIFLE", "RIFTE", "RIFTS", "RIFTY", "RIGHT", "RIGOL", "RILED", "RILES", 
        "RILEY", "RIMAE", "RIMED", "RIMES", "RIMUS", "RINDS", "RINDY", "RINES", "RINGS", "RINKS", "RINSE", "RIOJA", 
        "RIOTS", "RIPED", "RIPEN", "RIPES", "RISEN", "RISKY", "RITES", "RITZY", "RIVAL", "RIVAS", "RIVED", "RIVEL", 
        "RIVEN", "RIVES", "RIVET", "RIVOS", "RIYAL", "RIZAS", "ROACH", "ROADS", "ROAMS", "ROANS", "ROAST", "ROATE", 
        "ROBED", "ROBES", "ROBIN", "ROBLE", "ROCKS", "ROCKY", "RODES", "ROGUE", "ROGUY", "ROILS", "ROILY", "ROINS", 
        "ROIST", "ROJAK", "ROJIS", "ROKED", "ROKES", "ROLAG", "ROLES", "ROLFS", "ROMAL", "ROMAN", "ROMAS", "ROMPS", 
        "RONDE", "RONES", "RONTE", "RONTS", "ROPED", "ROPES", "ROPEY", "ROQUE", "ROSED", "ROSET", "ROSHI", "ROSIN", 
        "ROSIT", "ROSTI", "ROTAL", "ROTAN", "ROTAS", "ROTCH", "ROTED", "ROTES", "ROTIS", "ROTLS", "ROUEN", "ROUES", 
        "ROUGE", "ROUGH", "ROULE", "ROULS", "ROUMS", "ROUND", "ROUPS", "ROUPY", "ROUSE", "ROUST", "ROUTE", "ROUTH", 
        "ROUTS", "ROVED", "ROVEN", "ROVES", "ROWAN", "ROWDY", "ROWED", "ROWEL", "ROWEN", "ROWME", "ROWND", "ROWTH", 
        "ROWTS", "ROYAL", "ROYNE", "ROYST", "ROZET", "ROZIT", "RUBAI", "RUBEL", "RUBES", "RUBIN", "RUBLE", "RUCHE", 
        "RUCKS", "RUDAS", "RUDES", "RUDIE", "RUGAE", "RUGAL", "RUGBY", "RUING", "RUINS", "RUKHS", "RULED", "RULES", 
        "RUMAL", "RUMBA", "RUMBO", "RUMEN", "RUMES", "RUMLY", "RUMPO", "RUMPS", "RUMPY", "RUNCH", "RUNDS", "RUNED", 
        "RUNES", "RUNGS", "RUNIC", "RUNTS", "RUNTY", "RUPIA", "RUSHY", "RUSMA", "RUSTY", "RUTHS", "RUTIN", "RYALS", 
        "RYBAT", "RYKED", "RYKES", "RYNDS", "RYOTS"
    ],
    "S": [
        "SABED", "SABER", "SABIN", "SABIR", "SABLE", "SABOT", "SABRE", "SADHE", "SADHU", "SADLY", "SAFED", "SAFER", 
        "SAGER", "SAGUM", "SAHEB", "SAHIB", "SAICE", "SAICK", "SAINE", "SAINT", "SAITH", "SAJOU", "SAKER", "SALEP", 
        "SALET", "SALIC", "SALIX", "SALMI", "SALON", "SALOP", "SALTO", "SALTY", "SALUE", "SALVE", "SALVO", "SAMBO", 
        "SAMEK", "SAMEL", "SAMEN", "SAMEY", "SAMFU", "SAMPI", "SANDY", "SANED", "SANER", "SANGH", "SANGO", "SANKO", 
        "SANTO", "SAPID", "SAPOR", "SARED", "SARGE", "SARGO", "SARIN", "SARKY", "SAROD", "SATED", "SATEM", "SATIN", 
        "SATYR", "SAUCE", "SAUCH", "SAUCY", "SAUGH", "SAULT", "SAUNT", "SAURY", "SAUTE", "SAVED", "SAVER", "SAVEY", 
        "SAVIN", "SAVOR", "SAVOY", "SAWED", "SAWER", "SAYED", "SAYER", "SAYID", "SAYNE", "SAYON", "SCAIL", "SCALD", 
        "SCALE", "SCALP", "SCALY", "SCAMP", "SCAND", "SCANT", "SCAPE", "SCAPI", "SCARE", "SCARF", "SCARP", "SCART", 
        "SCARY", "SCATH", "SCAUD", "SCAUP", "SCAUR", "SCEAT", "SCENA", "SCEND", "SCENT", "SCHAV", "SCHMO", "SCHUL", 
        "SCHWA", "SCION", "SCLIM", "SCODY", "SCOLD", "SCONE", "SCOPA", "SCOPE", "SCORE", "SCORN", "SCOUG", "SCOUP", 
        "SCOUR", "SCOUT", "SCOWL", "SCOWP", "SCRAB", "SCRAE", "SCRAG", "SCRAM", "SCRAN", "SCRAP", "SCRAT", "SCRAW", 
        "SCRAY", "SCREW", "SCRIM", "SCRIP", "SCROD", "SCROG", "SCROW", "SCRUB", "SCRUM", "SCUBA", "SCUDI", "SCUDO", 
        "SCUFT", "SCULK", "SCULP", "SCURF", "SCUTA", "SCUTE", "SDAYN", "SDEIN", "SEAMY", "SEBUM", "SEDAN", "SEDGY", 
        "SEDUM", "SEGAR", "SEGNI", "SEGNO", "SEGOL", "SEITY", "SELAH", "SELVA", "SENGI", "SENOR", "SENTI", "SENVY", 
        "SENZA", "SEPAD", "SEPAL", "SEPIA", "SEPIC", "SEPOY", "SEPTA", "SERAC", "SERAI", "SERAL", "SERIC", "SERIF", 
        "SERIN", "SERON", "SEROW", "SERUM", "SERVO", "SETAL", "SETON", "SETUP", "SEWAN", "SEWAR", "SEWIN", "SEXTO", 
        "SHACK", "SHADE", "SHADY", "SHAFT", "SHAKE", "SHAKO", "SHAKT", "SHAKY", "SHALE", "SHALM", "SHALT", "SHALY", 
        "SHAME", "SHAND", "SHANK", "SHAPE", "SHARD", "SHARE", "SHARK", "SHARN", "SHARP", "SHAUL", "SHAVE", "SHAWL", 
        "SHAWM", "SHAWN", "SHEAF", "SHEAL", "SHEAR", "SHEIK", "SHELF", "SHEND", "SHENT", "SHEOL", "SHERD", "SHEVA", 
        "SHEWN", "SHIED", "SHIEL", "SHIER", "SHIFT", "SHILY", "SHINE", "SHINY", "SHIRE", "SHIRK", "SHIRT", "SHITE", 
        "SHIUR", "SHIVA", "SHIVE", "SHLEP", "SHLUB", "SHMEK", "SHOAL", "SHOAT", "SHOCK", "SHOED", "SHOER", "SHOGI", 
        "SHOJI", "SHOLA", "SHONE", "SHOPE", "SHORE", "SHORL", "SHORN", "SHORT", "SHOTE", "SHOUT", "SHOVE", "SHOWD", 
        "SHOWN", "SHOWY", "SHOYU", "SHRED", "SHREW", "SHROW", "SHRUB", "SHRUG", "SHTIK", "SHTUM", "SHTUP", "SHUCK", 
        "SHULE", "SHULN", "SHUNT", "SHURA", "SHUTE", "SHYER", "SIBYL", "SICHT", "SICKO", "SIDER", "SIDHA", "SIDHE", 
        "SIDLE", "SIELD", "SIENT", "SIETH", "SIEUR", "SIGHT", "SIGLA", "SIGMA", "SIGNA", "SIKER", "SILED", "SILEN", 
        "SILER", "SILEX", "SILKY", "SILTY", "SILVA", "SIMAR", "SIMBA", "SIMUL", "SINCE", "SINED", "SINEW", "SINGE", 
        "SINKY", "SIPED", "SIRED", "SIREN", "SIROC", "SIRUP", "SITAR", "SITED", "SITHE", "SITKA", "SITUP", "SIVER", 
        "SIXER", "SIXMO", "SIXTE", "SIXTH", "SIXTY", "SIZAR", "SIZED", "SIZEL", "SIZER", "SKAIL", "SKALD", "SKART", 
        "SKATE", "SKEAN", "SKEAR", "SKEIN", "SKELF", "SKELM", "SKELP", "SKIED", "SKIER", "SKIEY", "SKIMO", "SKIMP", 
        "SKINT", "SKIRL", "SKIRT", "SKITE", "SKIVE", "SKIVY", "SKLIM", "SKOAL", "SKORT", "SKRAN", "SKYED", "SKYER", 
        "SKYRE", "SKYTE", "SLACK", "SLADE", "SLAID", "SLAIN", "SLAKE", "SLANE", "SLANG", "SLANK", "SLANT", "SLART", 
        "SLATE", "SLATY", "SLAVE", "SLEPT", "SLICE", "SLICK", "SLIDE", "SLIER", "SLIME", "SLIMY", "SLING", "SLINK", 
        "SLIPE", "SLIPT", "SLIVE", "SLOAN", "SLOID", "SLOJD", "SLOPE", "SLOPY", "SLORM", "SLOTH", "SLOVE", "SLOYD", 
        "SLUED", "SLUIT", "SLUMP", "SLUNG", "SLUNK", "SLURB", "SLURP", "SLYER", "SLYPE", "SMACK", "SMAIK", "SMALT", 
        "SMART", "SMAZE", "SMEAR", "SMELT", "SMERK", "SMILE", "SMIRK", "SMITE", "SMITH", "SMOCK", "SMOKE", "SMOKY", 
        "SMOLT", "SMORE", "SMOTE", "SMOUT", "SMOWT", "SNACK", "SNAFU", "SNAIL", "SNAKE", "SNAKY", "SNARE", "SNARF", 
        "SNARK", "SNARL", "SNARY", "SNATH", "SNEAD", "SNEAK", "SNEAP", "SNECK", "SNICK", "SNIDE", "SNIFT", "SNIPE", 
        "SNIPY", "SNIRT", "SNOEK", "SNOEP", "SNOKE", "SNORE", "SNORT", "SNOUT", "SNOWK", "SNOWY", "SNUCK", "SOAPY", 
        "SOARE", "SOAVE", "SOBER", "SOCLE", "SODIC", "SOFAR", "SOFTA", "SOFTY", "SOGER", "SOILY", "SOKAH", "SOKEN", 
        "SOLAH", "SOLAN", "SOLAR", "SOLDE", "SOLDI", "SOLED", "SOLEI", "SOLER", "SOLID", "SOLUM", "SOLVE", "SOMAN", 
        "SONAR", "SONCE", "SONDE", "SONIC", "SONLY", "SOPHY", "SOPRA", "SORAL", "SORDA", "SORED", "SOREL", "SOREX", 
        "SORTA", "SOUCE", "SOUCT", "SOUGH", "SOUND", "SOUPY", "SOUTH", "SOWAR", "SOWCE", "SOWED", "SOWER", "SOWLE", 
        "SOWND", "SOWNE", "SOWTH", "SOYLE", "SOYUZ", "SOZIN", "SPACE", "SPACY", "SPADE", "SPADO", "SPAED", "SPAER", 
        "SPAHI", "SPAIL", "SPAIN", "SPAIT", "SPAKE", "SPALD", "SPALE", "SPALT", "SPANE", "SPANG", "SPANK", "SPARD", 
        "SPARE", "SPARK", "SPART", "SPATE", "SPAUL", "SPAWL", "SPAWN", "SPAYD", "SPEAK", "SPEAL", "SPEAN", "SPEAR", 
        "SPEAT", "SPECK", "SPEIL", "SPEIR", "SPELD", "SPELK", "SPELT", "SPEND", "SPENT", "SPERM", "SPEUG", "SPEWY", 
        "SPIAL", "SPICA", "SPICE", "SPICK", "SPICY", "SPIDE", "SPIED", "SPIEL", "SPIER", "SPIKE", "SPIKY", "SPILE", 
        "SPILT", "SPINA", "SPINE", "SPINK", "SPINY", "SPIRE", "SPIRT", "SPIRY", "SPITE", "SPITZ", "SPLAT", "SPLAY", 
        "SPLIT", "SPODE", "SPOIL", "SPOKE", "SPORE", "SPORT", "SPOUT", "SPRAD", "SPRAG", "SPRAT", "SPRAY", "SPRED", 
        "SPREW", "SPRIG", "SPRIT", "SPROD", "SPROG", "SPRUE", "SPRUG", "SPUED", "SPUER", "SPULE", "SPUME", "SPUMY", 
        "SPUNK", "SPURN", "SPURT", "SPUTA", "SPYAL", "SPYRE", "SQUAB", "SQUAD", "SQUAT", "SQUAW", "SQUEG", "SQUIB", 
        "SQUID", "SQUIT", "SQUIZ", "STACK", "STADE", "STAGE", "STAGY", "STAID", "STAIG", "STAIN", "STAIR", "STAKE", 
        "STALE", "STALK", "STAMP", "STAND", "STANE", "STANG", "STANK", "STAPH", "STARE", "STARK", "STARN", "STAUN", 
        "STAVE", "STEAD", "STEAK", "STEAL", "STEAM", "STEAN", "STEAR", "STEIL", "STEIN", "STELA", "STEND", "STENO", 
        "STERN", "STEWY", "STICH", "STICK", "STIED", "STILB", "STILE", "STIME", "STIMY", "STING", "STINK", "STIPA", 
        "STIPE", "STIRE", "STIRK", "STIRP", "STIVE", "STIVY", "STOAE", "STOAI", "STOCK", "STOEP", "STOGY", "STOIC", 
        "STOKE", "STOLE", "STOLN", "STOMA", "STOMP", "STOND", "STONE", "STONG", "STONK", "STONY", "STOPE", "STORE", 
        "STORK", "STORM", "STORY", "STOUN", "STOUP", "STOUR", "STOVE", "STOWN", "STOWP", "STRAD", "STRAE", "STRAG", 
        "STRAP", "STRAW", "STRAY", "STREP", "STREW", "STRIA", "STRIG", "STRIP", "STROP", "STROW", "STROY", "STRUM", 
        "STUCK", "STUDY", "STULM", "STUMP", "STUNG", "STUNK", "STUPA", "STUPE", "STURE", "STYED", "STYLE", "STYLI", 
        "STYLO", "STYME", "STYRE", "SUAVE", "SUBAH", "SUBER", "SUBHA", "SUCKY", "SUCRE", "SUDOR", "SUENT", "SUETY", 
        "SUGAN", "SUGAR", "SUING", "SUINT", "SUITE", "SULCI", "SULFA", "SULFO", "SULKY", "SUMAC", "SUMPH", "SUPER", 
        "SUPRA", "SURAH", "SURAL", "SURAT", "SURED", "SURFY", "SURGE", "SURGY", "SURLY", "SUTOR", "SUTRA", "SWACK", 
        "SWAGE", "SWAIL", "SWAIN", "SWALE", "SWALY", "SWAMI", "SWAMP", "SWAMY", "SWANG", "SWANK", "SWAPT", "SWARD", 
        "SWARE", "SWARF", "SWARM", "SWART", "SWATH", "SWAYL", "SWEAL", "SWEAR", "SWEAT", "SWEIR", "SWELT", "SWEPT", 
        "SWERF", "SWIFT", "SWINE", "SWING", "SWINK", "SWIPE", "SWIRE", "SWIRL", "SWITH", "SWIVE", "SWOLN", "SWONE", 
        "SWOPT", "SWORD", "SWORE", "SWORN", "SWOUN", "SWUNG", "SYBIL", "SYBOE", "SYBOW", "SYKER", "SYLPH", "SYLVA", 
        "SYMAR", "SYNCH", "SYNED", "SYNOD", "SYNTH", "SYPED", "SYRAH", "SYREN", "SYRUP", "SYTHE", "SYVER"
    ],
    "T": [
        "TABER", "TABES", "TABID", "TABIS", "TABLE", "TABOR", "TABUN", "TABUS", "TACES", "TACHE", "TACHO", "TACHS", 
        "TACKS", "TACKY", "TACOS", "TAELS", "TAHOU", "TAHRS", "TAIGS", "TAILS", "TAINS", "TAISH", "TAJES", "TAKEN", 
        "TAKER", "TAKES", "TAKHI", "TAKIN", "TAKIS", "TALCS", "TALCY", "TALER", "TALES", "TALKS", "TALKY", "TALON", 
        "TALUK", "TALUS", "TAMED", "TAMER", "TAMES", "TAMIN", "TAMIS", "TAMPS", "TANGI", "TANGO", "TANGS", "TANGY", 
        "TANHS", "TANKS", "TANKY", "TANSY", "TAPED", "TAPEN", "TAPER", "TAPES", "TAPIR", "TAPIS", "TAPUS", "TARDO", 
        "TARDY", "TARED", "TARES", "TARGE", "TARNS", "TAROC", "TAROK", "TAROS", "TARPS", "TARSI", "TASER", "TAUBE", 
        "TAULD", "TAUON", "TAUPE", "TAVER", "TAWED", "TAWER", "TAWIE", "TAWNY", "TAWSE", "TAXED", "TAXER", "TAXES", 
        "TAXIS", "TAXOL", "TAXON", "TAXOR", "TAXUS", "TEACH", "TEADS", "TEAKS", "TEALS", "TEAMS", "TEARS", "TEARY", 
        "TECHS", "TECHY", "TEGUA", "TEGUS", "TEHRS", "TEILS", "TEIND", "TELCO", "TELIA", "TELIC", "TELOI", "TELOS", 
        "TEMPI", "TEMPO", "TEMPS", "TENCH", "TENDS", "TENDU", "TENIA", "TENOR", "TEPAL", "TEPAS", "TEPID", "TEPOY", 
        "TERAI", "TERAS", "TERFS", "TERGA", "TERMS", "TERNS", "TESLA", "TEUCH", "TEUGH", "TEXAS", "THACK", "THAGI", 
        "THAIM", "THALI", "THANE", "THANK", "THANS", "THARM", "THARS", "THAWS", "THAWY", "THECA", "THEGN", "THEIC", 
        "THEIN", "THEIR", "THELF", "THEMA", "THENS", "THEOW", "THERM", "THESP", "THEWS", "THEWY", "THICK", "THIEF", 
        "THIGS", "THILK", "THINE", "THING", "THINK", "THINS", "THIOL", "THIRD", "THIRL", "THOLE", "THOLI", "THONG", 
        "THORN", "THORP", "THOSE", "THOUS", "THOWL", "THRAE", "THRAW", "THREW", "THRID", "THRIP", "THROB", "THROE", 
        "THROW", "THRUM", "THUDS", "THUGS", "THUJA", "THUMB", "THUMP", "THUNK", "THURL", "THUYA", "THYME", "THYMI", 
        "TIARS", "TICAL", "TICED", "TICES", "TICHY", "TICKS", "TICKY", "TIDAL", "TIDES", "TIERS", "TIGER", "TIGES", 
        "TIGON", "TIKAS", "TIKES", "TILAK", "TILDE", "TILED", "TILER", "TILES", "TIMBO", "TIMED", "TIMER", "TIMES", 
        "TIMON", "TIMPS", "TINDS", "TINEA", "TINED", "TINES", "TINGE", "TINGS", "TINKS", "TIPSY", "TIRED", "TIRES", 
        "TIRLS", "TIROS", "TOADS", "TOADY", "TOAZE", "TOCKS", "TOCKY", "TODAY", "TOEAS", "TOFUS", "TOGAE", "TOGAS", 
        "TOGED", "TOGES", "TOGUE", "TOILE", "TOILS", "TOING", "TOISE", "TOKAY", "TOKED", "TOKEN", "TOKER", "TOKES", 
        "TOLAN", "TOLAR", "TOLAS", "TOLED", "TOLES", "TOLUS", "TOMAN", "TOMBS", "TOMES", "TOMIA", "TONAL", "TONDI", 
        "TONED", "TONER", "TONES", "TONEY", "TONGA", "TONGS", "TONIC", "TONKA", "TONKS", "TONUS", "TOPAZ", "TOPED", 
        "TOPEK", "TOPER", "TOPES", "TOPHE", "TOPHI", "TOPHS", "TOPIC", "TOPIS", "TOQUE", "TORAH", "TORAN", "TORAS", 
        "TORCH", "TORCS", "TORES", "TORIC", "TORSE", "TORSI", "TORSK", "TORUS", "TOSED", "TOSHY", "TOUCH", "TOUGH", 
        "TOUKS", "TOUNS", "TOURS", "TOUSE", "TOUSY", "TOUZE", "TOUZY", "TOWED", "TOWEL", "TOWER", "TOWIE", "TOWNS", 
        "TOWNY", "TOWSE", "TOWSY", "TOWZE", "TOWZY", "TOXIC", "TOXIN", "TOYED", "TOYER", "TOZED", "TOZES", "TOZIE", 
        "TRABS", "TRACE", "TRACK", "TRADE", "TRADS", "TRAGI", "TRAIK", "TRAIL", "TRAIN", "TRAMP", "TRAMS", "TRANK", 
        "TRANQ", "TRANS", "TRAPE", "TRAPS", "TRASH", "TRAVE", "TRAWL", "TRAYS", "TREAD", "TRECK", "TREFA", "TREIF", 
        "TREKS", "TREMA", "TREND", "TREWS", "TREYS", "TRIAC", "TRIAD", "TRIAL", "TRIBE", "TRICE", "TRICK", "TRIDE", 
        "TRIED", "TRIES", "TRIGO", "TRIGS", "TRIKE", "TRILD", "TRIMS", "TRINE", "TRINS", "TRIOL", "TRIOS", "TRIPE", 
        "TRIPS", "TRIPY", "TROAD", "TROAK", "TROCK", "TRODE", "TRODS", "TROGS", "TROIS", "TROKE", "TROMP", "TRONA", 
        "TRONC", "TRONE", "TRONK", "TRONS", "TROPE", "TROVE", "TROWS", "TROYS", "TRUCE", "TRUCK", "TRUED", "TRUES", 
        "TRUGO", "TRUGS", "TRULY", "TRUMP", "TRUNK", "TRYKE", "TRYMA", "TRYPS", "TSADE", "TSADI", "TSKED", "TSUBA", 
        "TUANS", "TUBAE", "TUBAL", "TUBAR", "TUBAS", "TUBED", "TUBER", "TUBES", "TUCKS", "TUFAS", "TUGRA", "TUINA", 
        "TUISM", "TULES", "TULIP", "TULPA", "TUMID", "TUMOR", "TUMPS", "TUMPY", "TUNAS", "TUNDS", "TUNED", "TUNER", 
        "TUNES", "TUNGS", "TUNIC", "TUPEK", "TUPIK", "TUPLE", "TURBO", "TURDS", "TURFS", "TURFY", "TURKS", "TURME", 
        "TURMS", "TURNS", "TURPS", "TUSHY", "TUSKY", "TUXES", "TUYER", "TWAES", "TWAIN", "TWALS", "TWANG", "TWANK", 
        "TWAYS", "TWEAK", "TWERP", "TWICE", "TWIER", "TWIGS", "TWINE", "TWINK", "TWINS", "TWINY", "TWIRE", "TWIRL", 
        "TWIRP", "TWOER", "TWYER", "TYERS", "TYING", "TYKES", "TYLER", "TYMPS", "TYNDE", "TYNED", "TYNES", "TYPAL", 
        "TYPED", "TYPES", "TYPIC", "TYPOS", "TYRAN", "TYRED", "TYRES", "TYROS", "TZARS"
    ],
    "U": [
        "UDALS", "UDONS", "UGALI", "UHLAN", "UKASE", "ULANS", "ULCER", "ULEMA", "ULMIN", "ULNAD", "ULNAE", "ULNAR", 
        "ULNAS", "ULPAN", "ULTRA", "ULVAS", "ULYIE", "ULZIE", "UMBEL", "UMBER", "UMBLE", "UMBOS", "UMBRA", "UMBRE", 
        "UMIAC", "UMIAK", "UMIAQ", "UMPED", "UMPIE", "UMPTY", "UNAIS", "UNAPT", "UNARM", "UNARY", "UNBAG", "UNBAR", 
        "UNBED", "UNBID", "UNBOX", "UNCAP", "UNCES", "UNCIA", "UNCLE", "UNCOS", "UNCOY", "UNDAM", "UNDER", "UNETH", 
        "UNFED", "UNFIT", "UNFIX", "UNGET", "UNGOD", "UNGOT", "UNHAT", "UNHIP", "UNIFY", "UNITE", "UNITS", "UNITY", 
        "UNJAM", "UNKED", "UNKET", "UNKID", "UNLAW", "UNLAY", "UNLED", "UNLET", "UNLID", "UNLIT", "UNMET", "UNMEW", 
        "UNMIX", "UNPAY", "UNPEG", "UNRED", "UNRID", "UNRIG", "UNRIP", "UNSAY", "UNSET", "UNSEW", "UNSEX", "UNSOD", 
        "UNTAX", "UNTIE", "UNTIL", "UNWED", "UNWET", "UNWIT", "UNZIP", "UPBOW", "UPBYE", "UPDOS", "UPDRY", "UPEND", 
        "UPJET", "UPLAY", "UPLED", "UPLIT", "UPRAN", "UPSET", "UPSEY", "UPTAK", "UPTER", "UPTIE", "URAEI", "URALI", 
        "URAOS", "URASE", "URATE", "URBAN", "URBIA", "UREAL", "UREAS", "UREDO", "UREIC", "URENA", "URENT", "URGED", 
        "URGES", "URIAL", "URINE", "URITE", "URMAN", "URNAL", "URNED", "URPED", "URSAE", "URSID", "URSON", "URVAS", 
        "USAGE", "USHER", "USING", "USNEA", "UTERI", "UTILE", "UVEAL", "UVEAS"
    ],
    "V": [
        "VADES", "VAGUE", "VAGUS", "VAILS", "VAIRE", "VAIRS", "VAIRY", "VAKIL", "VALES", "VALET", "VALID", "VALIS", 
        "VALOR", "VALSE", "VALUE", "VAMPS", "VAMPY", "VANED", "VANES", "VANGS", "VANTS", "VAPID", "VAPOR", "VARDY", 
        "VAREC", "VARES", "VARIX", "VARUS", "VASTY", "VATIC", "VATUS", "VAUCH", "VAULT", "VAUNT", "VAUTE", "VAUTS", 
        "VAWTE", "VEALS", "VEALY", "VEGAN", "VEGAS", "VEGOS", "VEILS", "VEILY", "VEINS", "VEINY", "VELAR", "VELDS", 
        "VELDT", "VELUM", "VENAL", "VENDS", "VENOM", "VENTS", "VENUS", "VERBS", "VERSO", "VERST", "VERTS", "VERTU", 
        "VESPA", "VESTA", "VETCH", "VEXIL", "VEZIR", "VIALS", "VIAND", "VIBES", "VIBEX", "VIBEY", "VICAR", "VICED", 
        "VICES", "VICHY", "VIDEO", "VIERS", "VIEWS", "VIEWY", "VIFDA", "VIGAS", "VIGOR", "VILDE", "VILER", "VIMEN", 
        "VINAL", "VINAS", "VINCA", "VINED", "VINER", "VINES", "VINEW", "VINOS", "VINTS", "VINYL", "VIOLA", "VIOLD", 
        "VIOLS", "VIPER", "VIRAL", "VIRED", "VIREO", "VIRES", "VIRGA", "VIRGE", "VIRLS", "VIRTU", "VIRUS", "VISED", 
        "VISNE", "VISON", "VISOR", "VISTA", "VISTO", "VITAE", "VITAL", "VITAS", "VITEX", "VIXEN", "VIZOR", "VLEIS", 
        "VLIES", "VOARS", "VOCAB", "VOCAL", "VOCES", "VODKA", "VODUN", "VOEMA", "VOGIE", "VOGUE", "VOICE", "VOIDS", 
        "VOILA", "VOILE", "VOLAE", "VOLAR", "VOLED", "VOLES", "VOLET", "VOLKS", "VOLTA", "VOLTE", "VOLTI", "VOLTS", 
        "VOMER", "VOMIT", "VOTED", "VOTER", "VOTES", "VOUCH", "VOUGE", "VOWED", "VOWEL", "VOWER", "VOXEL", "VOZHD", 
        "VRAIC", "VRILS", "VROUS", "VROUW", "VROWS", "VUGHS", "VUGHY", "VULGO", "VULNS", "VYING"
    ],
    "W": [
        "WACKE", "WACKO", "WACKS", "WACKY", "WADER", "WADES", "WADIS", "WADTS", "WAFER", "WAFTS", "WAGED", "WAGER", 
        "WAGES", "WAGON", "WAIDE", "WAIFS", "WAIFT", "WAILS", "WAINS", "WAIRS", "WAIST", "WAITE", "WAITS", "WAIVE", 
        "WAKED", "WAKEN", "WAKER", "WAKES", "WAKFS", "WALDO", "WALDS", "WALED", "WALER", "WALES", "WALIS", "WALKS", 
        "WALTY", "WALTZ", "WAMED", "WAMES", "WAMUS", "WANDS", "WANED", "WANES", "WANEY", "WANGS", "WANKS", "WANKY", 
        "WANLE", "WANLY", "WANTS", "WANTY", "WANZE", "WAQFS", "WARBS", "WARBY", "WARDS", "WARED", "WARES", "WAREZ", 
        "WARKS", "WARMS", "WARNS", "WARPS", "WARST", "WARTS", "WARTY", "WASHY", "WASPY", "WASTE", "WATCH", "WATER", 
        "WAUGH", "WAUKS", "WAULK", "WAULS", "WAURS", "WAVED", "WAVER", "WAVES", "WAVEY", "WAXED", "WAXEN", "WAXER", 
        "WAXES", "WAYED", "WAZIR", "WEALD", "WEALS", "WEAMB", "WEANS", "WEARS", "WEARY", "WECHT", "WEDGY", "WEFTS", 
        "WEIDS", "WEIGH", "WEILS", "WEIRD", "WEIRS", "WEKAS", "WELCH", "WELDS", "WELKS", "WELKT", "WELSH", "WELTS", 
        "WEMBS", "WENCH", "WENDS", "WENTS", "WEROS", "WERSH", "WETAS", "WETLY", "WHACK", "WHALE", "WHAMO", "WHAMS", 
        "WHANG", "WHAPS", "WHARE", "WHARF", "WHATS", "WHAUP", "WHAUR", "WHEAL", "WHEAR", "WHEAT", "WHEFT", "WHELK", 
        "WHELM", "WHELP", "WHENS", "WHETS", "WHEYS", "WHIDS", "WHIFT", "WHIGS", "WHILE", "WHILK", "WHIMS", "WHINE", 
        "WHINS", "WHINY", "WHIPS", "WHIPT", "WHIRL", "WHIRS", "WHISK", "WHIST", "WHITE", "WHITS", "WHITY", "WHOLE", 
        "WHOMP", "WHOPS", "WHORE", "WHORL", "WHORT", "WHOSE", "WHUMP", "WHUPS", "WICKS", "WICKY", "WIDEN", "WIDER", 
        "WIDES", "WIDTH", "WIELD", "WIELS", "WIFED", "WIFES", "WIFEY", "WIFTY", "WIGAN", "WIGHT", "WILCO", "WILDS", 
        "WILED", "WILES", "WILGA", "WILJA", "WILTS", "WIMPS", "WIMPY", "WINCE", "WINCH", "WINDS", "WINDY", "WINED", 
        "WINES", "WINEY", "WINGE", "WINGS", "WINGY", "WINKS", "WINOS", "WINZE", "WIPED", "WIPER", "WIPES", "WIRED", 
        "WIRES", "WISED", "WISER", "WISHA", "WISHT", "WISPY", "WITAN", "WITCH", "WITED", "WITES", "WITHE", "WITHS", 
        "WITHY", "WIVED", "WIVER", "WIVES", "WIZEN", "WIZES", "WOADS", "WOALD", "WOCKS", "WODGE", "WOFUL", "WOKEN", 
        "WOLDS", "WOLFS", "WOLVE", "WOMAN", "WOMBS", "WOMBY", "WOMEN", "WOMYN", "WONGA", "WONGI", "WONKS", "WONKY", 
        "WONTS", "WORDS", "WORDY", "WORKS", "WORLD", "WORMS", "WORMY", "WORSE", "WORST", "WORTH", "WORTS", "WOULD", 
        "WOUND", "WOVEN", "WOXEN", "WRACK", "WRANG", "WRAPS", "WRAPT", "WRAST", "WRATE", "WRATH", "WREAK", "WRECK", 
        "WRENS", "WREST", "WRICK", "WRIED", "WRIES", "WRING", "WRIST", "WRITE", "WRITS", "WROKE", "WRONG", "WROTE", 
        "WROTH", "WRUNG", "WURST", "WUXIA", "WYLED", "WYLES", "WYNDS", "WYTED", "WYTES"
    ],
    "X": [
        "XENIA", "XENIC", "XERIC", "XERUS", "XYLAN", "XYLEM", "XYLIC", "XYSTI"
    ],
    "Y": [
        "YACHT", "YACKS", "YAGER", "YAGIS", "YAIRD", "YAKOW", "YALES", "YAMEN", "YAMUN", "YANGS", "YANKS", "YAPOK", 
        "YAPON", "YARCO", "YARDS", "YARKS", "YARNS", "YARTO", "YATES", "YAUDS", "YAULD", "YAUPS", "YAWED", "YAWLS", 
        "YAWNS", "YAWPS", "YBORE", "YCLAD", "YCLED", "YCOND", "YEADS", "YEAHS", "YEALM", "YEANS", "YEARD", "YEARN", 
        "YEARS", "YEAST", "YECHS", "YELKS", "YELMS", "YELPS", "YELTS", "YENTA", "YERBA", "YERDS", "YERKS", "YETIS", 
        "YEUKS", "YIELD", "YIKED", "YIKES", "YINCE", "YIPES", "YIRDS", "YIRKS", "YIRTH", "YITES", "YLEMS", "YLIKE", 
        "YLKES", "YMOLT", "YMPES", "YOCKS", "YODEL", "YODHS", "YODLE", "YOGAS", "YOGHS", "YOGIC", "YOGIN", "YOGIS", 
        "YOICK", "YOJAN", "YOKED", "YOKEL", "YOKER", "YOKES", "YOKUL", "YOLKS", "YOMPS", "YONIC", "YONIS", "YONKS", 
        "YORES", "YORKS", "YORPS", "YOUKS", "YOUNG", "YOURN", "YOURS", "YOURT", "YOUSE", "YOUTH", "YOWED", "YOWES", 
        "YOWIE", "YOWLS", "YRAPT", "YRENT", "YRIVD", "YRNEH", "YSAME", "YUANS", "YUCAS", "YUCKO", "YUCKS", "YUFTS", 
        "YUGAS", "YUKED", "YUKES", "YUKOS", "YULAN", "YULES", "YUMPS", "YUPON", "YURTA", "YURTS"
    ],
    "Z": [
        "ZACKS", "ZAIRE", "ZAMBO", "ZANTE", "ZARFS", "ZATIS", "ZAXES", "ZAYIN", "ZEALS", "ZEBRA", "ZEBUS", "ZEINS", 
        "ZERDA", "ZERKS", "ZEROS", "ZESTY", "ZETAS", "ZIBET", "ZIGAN", "ZILAS", "ZILCH", "ZIMBS", "ZINCO", "ZINCS", 
        "ZINCY", "ZINEB", "ZINES", "ZINGS", "ZINGY", "ZINKE", "ZINKY", "ZIRAM", "ZLOTE", "ZLOTY", "ZOBUS", "ZOEAL", 
        "ZOEAS", "ZOISM", "ZOIST", "ZOMBI", "ZONAE", "ZONAL", "ZONDA", "ZONED", "ZONER", "ZONES", "ZONKS", "ZORIL", 
        "ZORIS", "ZOUKS", "ZOWIE", "ZUPAN", "ZUPAS", "ZURFS", "ZYGAL", "ZYGON", "ZYMES", "ZYMIC"
    ],
}